import { Helmet } from "react-helmet";
import {
  Breadcrumbs,
  BreadcrumbProps,
  Spinner,
  NonIdealState,
} from "@blueprintjs/core";
import { useHistory } from "react-router-dom";

import { AgHistoryArticleTeaser } from "../../components/AgHistoryNews";

import { useContent } from "../../hooks/useContent";

import styles from "./AgHistoryListing.module.css";

export function AgHistoryListing() {
  const history = useHistory();
  const { isLoading, data: articles } = useContent("student_news", 20);

  const agHistoryListingBreadcrumbs: BreadcrumbProps[] = [
    { onClick: () => history.push("/students"), text: "Students" },
    {
      text: "Ag & History News",
    },
  ];

  return (
    <div className={styles.landingPage}>
      <Helmet>
        <title>{`Ag & History News - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>

      <header>
        <h1>Ag &amp; History News</h1>
      </header>
      <Breadcrumbs
        className={styles.breadcrumbs}
        items={agHistoryListingBreadcrumbs}
      />

      <section className={styles.section}>
        {isLoading && <Spinner />}
        {!isLoading && articles?.length === 0 && (
          <NonIdealState
            title="No published ag & history news."
            icon="document"
            description="Check back later for more news."
            className={styles.empty}
          />
        )}
        {articles?.map((a) => {
          return <AgHistoryArticleTeaser key={a.id} content={a} />;
        })}
      </section>
    </div>
  );
}
