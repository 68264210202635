import { Button, ButtonGroup, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import classNames from "classnames/bind";

import styles from "./ActivityToolbar.module.css";

const cx = classNames.bind(styles);

interface ActivityToolbarProps {
  loading: boolean;
  savedActivities: { id: string; name: string }[];

  onNewActivity?: () => void;
  onOpenActivity?: (id: string) => void;
  onPrintActivity?: () => void;
  onSaveActivity?: () => void;
}

export function ActivityToolbar({
  loading,
  savedActivities,
  onNewActivity,
  onOpenActivity,
  onPrintActivity,
  onSaveActivity,
}: ActivityToolbarProps) {
  return (
    <div
      className={cx("activityToolbar", {
        noPrint: true,
      })}
    >
      <strong>Activity Menu</strong>
      <ButtonGroup>
        {onNewActivity && (
          <Button disabled={loading} onClick={onNewActivity} icon="document">
            New
          </Button>
        )}

        {onOpenActivity && (
          <Popover2
            content={
              <Menu>
                {savedActivities.map((a) => {
                  return (
                    <MenuItem
                      key={a.id}
                      onClick={() => onOpenActivity(a.id)}
                      text={a.name}
                    />
                  );
                })}
              </Menu>
            }
            placement="bottom-start"
          >
            <Button
              disabled={loading}
              icon="folder-open"
              rightIcon="caret-down"
            >
              Open
            </Button>
          </Popover2>
        )}

        {onPrintActivity && (
          <Button disabled={loading} onClick={onPrintActivity} icon="print">
            Print...
          </Button>
        )}

        {onSaveActivity && (
          <Button
            loading={loading}
            onClick={onSaveActivity}
            intent="primary"
            icon="cloud-upload"
          >
            Save
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}
