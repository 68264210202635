import { useState } from "react";
import * as filesize from "filesize";
import { Helmet } from "react-helmet";
import { Tree, TreeNodeInfo, Button, Toaster } from "@blueprintjs/core";
import { AppError } from "../../lib/errors";
import { useResources } from "../../hooks/useResources";
import { apiDownloadResource } from "../../lib/net";

import styles from "./ResourcesPage.module.css";

export function ResourcesPage() {
  const [currentPath, setCurrentPath] = useState("/");
  const [parentPath, setParentPath] = useState("/");

  const { isLoading, data, refetch } = useResources(currentPath);

  const treeState: TreeNodeInfo[] = data
    ? data.map((resource): TreeNodeInfo => {
        if (resource.is_directory) {
          return {
            id: resource.path,
            hasCaret: false,
            icon: "folder-close",
            label: resource.name,
            secondaryLabel: "n/a",
            nodeData: {
              isDirectory: true,
              downloadLink: "",
            },
          };
        } else {
          return {
            id: resource.path,
            hasCaret: false,
            icon: "document",
            label: resource.name,
            secondaryLabel: filesize.default(resource.size),
            nodeData: {
              isDirectory: false,
              downloadLink: resource.download_link,
            },
          };
        }
      })
    : [];

  const onNewPath = async (newPath: string) => {
    await setCurrentPath(newPath);
    const pathArray = newPath.split("/");
    pathArray.pop();

    let newParentPath = pathArray.join("/");
    if (newParentPath === "") {
      newParentPath = "/";
    }

    setParentPath(newParentPath);
    refetch();
  };

  const onParentPathClick = () => {
    onNewPath(parentPath);
  };

  const onFileDownload = async (path: string, name: string) => {
    const toaster = Toaster.create();
    try {
      const key = toaster.show({
        message: `Downloading ${name}...`,
        intent: "success",
        timeout: 0,
      });
      await apiDownloadResource(path, name);
      toaster.dismiss(key);
    } catch (err) {
      toaster.clear();

      toaster.show({
        message:
          "There was a problem trying to download this resource: " +
          (err as AppError).message,
        intent: "danger",
      });
    }
  };

  return (
    <div className="landingPage">
      <Helmet>
        <title>{`Teacher Resources - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <header>
        <h1>Resources</h1>
      </header>

      <section className="section">
        <h1>Downloadable Resources</h1>

        <div className={styles.fileBrowser}>
          <header>
            <Button
              onClick={onParentPathClick}
              loading={isLoading}
              icon="double-chevron-up"
              text="Up Directory"
            />
            <h1>Current Path: {currentPath}</h1>
          </header>
          <Tree
            contents={treeState}
            onNodeDoubleClick={(node) => {
              if ((node.nodeData as any).isDirectory) {
                onNewPath(node.id as string);
              } else {
                if ((node.nodeData as any).downloadLink === "") {
                  onFileDownload(node.id as string, node.label as string);
                } else {
                  window.open((node.nodeData as any).downloadLink, "_blank");
                }
              }
            }}
          />
        </div>
      </section>
    </div>
  );
}
