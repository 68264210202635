import { useState } from "react";
import { Helmet } from "react-helmet";
import { useQueryClient } from "react-query";
import { Button, NonIdealState, Spinner } from "@blueprintjs/core";

import { StudentGroupComponent } from "../../components/StudentGroup";
import { NewStudentGroupForm } from "../../components/NewStudentGroupForm";
import { NewStudentForm } from "../../components/NewStudentForm";
import { ImportStudentsForm } from "../../components/ImportStudentsForm";
import { DeleteConfirmationDialog } from "../../components/DeleteConfirmationDialog";

import { apiDeleteStudent, apiDeleteStudentGroup } from "../../lib/net";
import { StudentGroup, Student } from "../../lib/definitions";

import { useStudentGroups } from "../../hooks/useStudents";

import styles from "./StudentPage.module.css";

export function StudentPage() {
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);
  const [showNewStudentForm, setShowNewStudentForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [editStudent, setEditStudent] = useState<Student | null>(null);
  const [editGroup, setEditGroup] = useState<StudentGroup | null>(null);

  const queryClient = useQueryClient();

  const { isLoading, isError, data } = useStudentGroups();

  const onCreateGroupBtn = () => {
    setShowGroupForm(true);
  };

  const onSelectEditStudent = (student: Student) => {
    setEditStudent(student);
    setShowNewStudentForm(true);
  };

  const onSelectDeleteStudent = (student: Student) => {
    setEditStudent(student);
    setShowDeleteForm(true);
  };

  const onDeleteStudentGroup = (group: StudentGroup) => {
    setEditGroup(group);
    setShowDeleteForm(true);
  };

  const onDeleteStudent = async () => {
    if (editStudent) {
      try {
        await apiDeleteStudent(editStudent.group_id, editStudent.id);
        setShowDeleteForm(false);
        setEditStudent(null);
        queryClient.invalidateQueries("students");
      } catch (err) {
        // TODO:
      }
    }
  };

  const onDeleteGroup = async () => {
    if (editGroup) {
      try {
        await apiDeleteStudentGroup(editGroup);
        setShowDeleteForm(false);
        setEditGroup(null);
        queryClient.invalidateQueries("studentgroups");
      } catch (err) {
        // TODO:
      }
    }
  };

  return (
    <div className="landingPage">
      <Helmet>
        <title>{`Student Management - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <header>
        <h1>Students</h1>
      </header>

      <section className="section">
        <h1>Student Management (21-22)</h1>
        <header className={styles.buttonHeader}>
          <Button
            intent="primary"
            onClick={onCreateGroupBtn}
            text="Create Group"
          />
          <Button
            intent="primary"
            onClick={() => setShowNewStudentForm(true)}
            text="Add Student"
          />
          <Button
            onClick={() => {
              setShowImportForm(true);
            }}
            text="Import Students"
          />
        </header>

        <NewStudentGroupForm
          showForm={showGroupForm}
          editGroup={editGroup}
          onClose={() => {
            setShowGroupForm(false);
            setEditGroup(null);
          }}
        />

        <NewStudentForm
          showForm={showNewStudentForm}
          editStudent={editStudent}
          groups={data || []}
          onClose={() => {
            setShowNewStudentForm(false);
            setEditStudent(null);
          }}
        />

        <ImportStudentsForm
          showForm={showImportForm}
          groups={data || []}
          onClose={() => {
            setShowImportForm(false);
          }}
        />

        <DeleteConfirmationDialog
          showForm={showDeleteForm && editStudent !== null}
          itemName={editStudent?.username}
          onClose={() => {
            setShowDeleteForm(false);
            setEditStudent(null);
          }}
          onConfirm={onDeleteStudent}
        />

        <DeleteConfirmationDialog
          showForm={showDeleteForm && editGroup !== null}
          itemName={editGroup?.name}
          onClose={() => {
            setShowDeleteForm(false);
            setEditGroup(null);
          }}
          onConfirm={onDeleteGroup}
        />

        {data?.map((group) => (
          <StudentGroupComponent
            key={group.id}
            group={group}
            onSelectDeleteStudent={onSelectDeleteStudent}
            onSelectEditStudent={onSelectEditStudent}
            onNewStudent={() => {
              setShowNewStudentForm(true);
            }}
            onDeleteGroup={onDeleteStudentGroup}
          />
        ))}

        {isLoading && <Spinner />}

        {!isLoading && !isError && data?.length === 0 && (
          <NonIdealState
            title="You have no student groups yet"
            icon="new-person"
            description="Organize your students by grouping them by class."
            action={
              <Button
                intent="primary"
                text="Add the first group"
                onClick={onCreateGroupBtn}
              />
            }
          />
        )}
      </section>
    </div>
  );
}
