import { useField, FieldHookConfig } from "formik";
import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";

export interface LoginFormFieldInterface {
  label: string;
  hint?: string;
}

export const TextFormField = (
  props: LoginFormFieldInterface & FieldHookConfig<string>
) => {
  const [field, details] = useField(props);
  let intent: Intent = "none";
  if (details.error) {
    intent = "danger";
  }

  return (
    <FormGroup
      label={props.label}
      intent={intent}
      helperText={details.error ?? props.hint}
    >
      <InputGroup
        {...field}
        type={props.type}
        name={props.name}
        required={props.required}
        disabled={props.disabled}
        intent={intent}
      />
    </FormGroup>
  );
};
