import { ResetPasswordForm } from "../components/ResetPasswordForm";
import styles from "./Login.module.css";

import logo from "../assets/logo.svg";

import { Helmet } from "react-helmet";

export function ResetPassword() {
  return (
    <div className="page">
      <Helmet>
        <title>{`Reset Password - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <div className={styles.loginPage}>
        <h1 className={styles.logo}>
          <img src={logo} alt="Tour SD!" />
          <small>Portal Version 2.0-alpha</small>
        </h1>
        <ResetPasswordForm />
      </div>
    </div>
  );
}
