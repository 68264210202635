import { useQuery } from "react-query";
import { Town, TownSnippet } from "../lib/definitions";
import { AppError } from "../lib/errors";
import { apiGetTowns, apiGetTown } from "../lib/net";

export function useTowns() {
  return useQuery<TownSnippet[], AppError>("towns", () => {
    return apiGetTowns();
  });
}

export function useTown(slug: string) {
  return useQuery<Town, AppError>(
    [
      "town",
      {
        slug,
      },
    ],
    () => {
      return apiGetTown(slug);
    }
  );
}
