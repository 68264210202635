import { useCallback, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { jsPDF } from "jspdf";
import { ButtonGroup, Button, FormGroup, NumericInput, Menu, MenuItem, Toaster } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { ChromePicker } from "react-color";

import { ActivityToolbar } from "./ActivityToolbar";
import { apiPutActivityData, apiSaveActivityData } from "../lib/net";
import { useActivityData } from "../hooks/useActivity";

import mapBuilderBlankImage from "../assets/mapbuilder_blank.png";
import mapBuilderCapitalIcon from "../assets/icon-capital.png";
import mapBuilderCityIcon from "../assets/icon-city.png";

import styles from "./MapBuilder.module.css";

interface Map {
  id?: string;
  name: string;
  data: any;
}

interface MapToolbarProps {
  color: string;
  fontSize: number;
  strokeWidth: number;
  drawingMode: boolean;
  onColorChange: (newColor: string) => void;
  onFontSizeChange: (newSize: number) => void;
  onStrokeWidthChange: (newSize: number) => void;
  toggleDrawingMode: () => void;
  onAddText: () => void;
  onDeleteSelections: () => void;
  onDownloadCanvas: () => void;
  onAddImage: (image: string) => void;
}

export function MapBuilder() {
  const { isLoading: loadingActivities, data } = useActivityData("map-builder");

  const savedMaps = data
    ? data.map((d) => {
        return {
          id: d.id || "",
          name: d.data.name,
        };
      })
    : [];

  const [canvas, setCanvas] = useState<fabric.Canvas | undefined>();
  const [selectedColor, setSelectedColor] = useState<string>("#000000");
  const [fontSize, setFontSize] = useState<number>(20);
  const [strokeWidth, setStrokeWidth] = useState<number>(1);
  const [drawingMode, setDrawingMode] = useState<boolean>(false);
  const [canvasSelection, setCanvasSelection] = useState<any[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [map, setMap] = useState<Map>({
    name: "",
    data: "",
  });

  const saveActivity = async () => {
    if (dirty) {
      const toaster = Toaster.create();

      try {
        const saveMap = { ...map };
        const canvasData = JSON.stringify(canvas);
        saveMap.data = canvasData;

        const data: any = JSON.stringify(saveMap);
        setIsSaving(true);
        if (saveMap.id) {
          await apiPutActivityData("map-builder", {
            id: map.id,
            data,
          });
        } else {
          const id = await apiSaveActivityData("map-builder", {
            data,
          });

          saveMap.id = id;
          setMap(saveMap);
        }

        setIsSaving(false);
        setDirty(false);

        toaster.show({
          message: "Activity Saved.",
          intent: "success",
        });
      } catch (err: any) {
        setIsSaving(false);
        toaster.show({
          message: "Error saving activity: " + (err as Error).message,
        });
      }
    }
  };
  const newActivity = () => {
    if (dirty) {
      if (!window.confirm("The current map is unsaved.  Start a new map?")) {
        return;
      }

      setMap({
        name: "",
        data: "",
      });

      newCanvas();
    }
  };

  const openActivity = (id: string) => {
    if (data && canvas) {
      const openMap = data.find((d) => d.id === id);
      if (openMap) {
        setMap({ ...openMap.data, id: openMap.id });

        // build canvas
        canvas.loadFromJSON(openMap.data.data, () => {
          console.log("saved map loaded");
        });
      }
    }
  };
  const printActivity = () => {
    if (canvas) {
      const dataURL = canvas.toDataURL({
        format: "jpeg",
        quality: 1.0,
      });

      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [8.5, 11],
      });
      pdf.addImage(dataURL, "JPEG", 0, 0, 11, 8.5);

      const w = pdf.output("dataurlnewwindow");
      w.focus();
    }
  };

  const onObjectSelected = (e: any) => {
    setCanvasSelection(e.selected);
  };
  const onObjectSelectedUpdated = (e: any) => {
    setCanvasSelection(e.selected);
  };
  const onObjectSelectedCleared = (e: any) => {
    setCanvasSelection([]);
  };

  const onCanvasChanged = (e: any) => {
    setDirty(true);
  };

  const updateMapName = (newName: string) => {
    const updatedMap = { ...map };
    updatedMap.name = newName;
    setMap(updatedMap);
  };

  const checkDirtyBeforeRefresh = useCallback(() => {
    if (dirty) {
      return "The current map has not been saved.  Discard changes?";
    }
  }, [dirty]);

  const newCanvas = useCallback(() => {
    if (canvas) {
      canvas.dispose();
    }

    const newCanvas = new fabric.Canvas("mapbuilder_canvas");
    newCanvas.setBackgroundImage(mapBuilderBlankImage, newCanvas.renderAll.bind(newCanvas), {
      width: newCanvas.width,
      height: newCanvas.height,
      originX: "left",
      originY: "top",
    });

    newCanvas.isDrawingMode = drawingMode;
    newCanvas.on("selection:created", onObjectSelected);
    newCanvas.on("selection:updated", onObjectSelectedUpdated);
    newCanvas.on("selection:cleared", onObjectSelectedCleared);
    newCanvas.on("object:modified", onCanvasChanged);
    newCanvas.on("object:added", onCanvasChanged);
    newCanvas.on("object:removed", onCanvasChanged);
    setCanvas(newCanvas);
  }, [canvas, drawingMode]);

  useEffect(() => {
    if (!canvas) {
      newCanvas();
    }

    window.onbeforeunload = checkDirtyBeforeRefresh;
  }, [canvas, newCanvas, checkDirtyBeforeRefresh]);

  useEffect(() => {
    if (canvas) {
      canvas.isDrawingMode = drawingMode;
    }
  }, [canvas, drawingMode]);

  return (
    <div className={styles.mapBuilder}>
      <ActivityToolbar
        loading={isSaving || loadingActivities}
        savedActivities={savedMaps}
        onSaveActivity={saveActivity}
        onNewActivity={newActivity}
        onOpenActivity={openActivity}
        onPrintActivity={printActivity}
      />
      <div className={styles.mapName}>
        <input
          type="text"
          placeholder="Give your map a name..."
          value={map.name}
          onChange={(e) => updateMapName(e.target.value)}
        />
      </div>
      <div className={styles.columns}>
        <MapToolbar
          drawingMode={drawingMode}
          color={selectedColor}
          fontSize={fontSize}
          strokeWidth={strokeWidth}
          onColorChange={(color) => setSelectedColor(color)}
          onFontSizeChange={(size) => setFontSize(size)}
          onStrokeWidthChange={(size) => setStrokeWidth(size)}
          toggleDrawingMode={() => {
            if (canvas) {
              setDrawingMode(!drawingMode);

              canvas.freeDrawingBrush.color = selectedColor;
              canvas.freeDrawingBrush.width = strokeWidth;
            }
          }}
          onDeleteSelections={() => {
            if (canvas) {
              canvasSelection.forEach((s) => {
                canvas.remove(s);
              });
            }
          }}
          onAddText={() => {
            if (canvas) {
              const newText = new fabric.IText("Change text here", {
                fontSize: fontSize,
                fill: selectedColor,
              });

              canvas.add(newText);
            }
          }}
          onAddImage={(image) => {
            if (canvas) {
              if (image === "capital-icon") {
                fabric.Image.fromURL(mapBuilderCapitalIcon, (oImg) => {
                  canvas.add(oImg);
                });
              }

              if (image === "city-icon") {
                fabric.Image.fromURL(mapBuilderCityIcon, (oImg) => {
                  canvas.add(oImg);
                });
              }
            }
          }}
          onDownloadCanvas={() => {
            if (canvas) {
              const dataURL = canvas.toDataURL({
                format: "jpeg",
                quality: 1.0,
              });

              const pdf = new jsPDF({
                orientation: "landscape",
                unit: "in",
                format: [8.5, 11],
              });
              pdf.addImage(dataURL, "JPEG", 0, 0, 11, 8.5);
              pdf.save(map.name + ".pdf", {
                returnPromise: true,
              });
            }
          }}
        />
        <MapCanvas />
      </div>
    </div>
  );
}

export function MapCanvas() {
  const canvasRef = useRef<any>(null);

  useEffect(() => {
    if (!canvasRef) {
      return;
    }
  }, [canvasRef]);
  return (
    <div className={styles.canvas} ref={canvasRef}>
      <canvas width={850} height={549} id="mapbuilder_canvas"></canvas>
    </div>
  );
}

function MapToolbar({
  color,
  drawingMode,
  fontSize,
  strokeWidth,
  onColorChange,
  onFontSizeChange,
  onStrokeWidthChange,
  onDeleteSelections,
  onAddText,
  onAddImage,
  onDownloadCanvas,
  toggleDrawingMode,
}: MapToolbarProps) {
  return (
    <div className={styles.toolbar}>
      <div className={styles.controls}>
        <ButtonGroup vertical large>
          <Button
            fill
            text="Add Text"
            onClick={() => {
              onAddText();
            }}
          />
          <Popover2
            content={
              <Menu>
                <MenuItem text="Capital Icon" onClick={() => onAddImage("capital-icon")} />
                <MenuItem text="City Icon" onClick={() => onAddImage("city-icon")} />
              </Menu>
            }
            placement="right"
          >
            <Button fill text="Add Image" />
          </Popover2>
          <Button
            active={drawingMode}
            fill
            text="Enable Drawing Mode"
            onClick={() => {
              toggleDrawingMode();
            }}
          />
          <Button
            intent="danger"
            text="Delete Selection"
            onClick={() => {
              onDeleteSelections();
            }}
          />
          <Button
            intent="primary"
            text="Export Drawing"
            onClick={() => {
              onDownloadCanvas();
            }}
          />
        </ButtonGroup>
      </div>

      <div className={styles.designControls}>
        <ChromePicker color={color} onChangeComplete={(e) => onColorChange(e.hex)} />
        <FormGroup label="Font Size">
          <NumericInput fill value={fontSize} onValueChange={(val) => onFontSizeChange(val)} />
        </FormGroup>

        <FormGroup label="Line Width">
          <NumericInput fill value={strokeWidth} onValueChange={(val) => onStrokeWidthChange(val)} />
        </FormGroup>
      </div>
    </div>
  );
}
