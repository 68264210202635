import { FormGroup, Intent } from "@blueprintjs/core";
import { useField, FieldHookConfig } from "formik";
import React from "react";
import Select from "react-select";

export interface FormFieldProps {
  label: string;
  hint?: string;
  options?: {
    label: string;
    value: any;
  }[];
}

export const SearchSelectFormField = (
  props: FormFieldProps & FieldHookConfig<string>
) => {
  const [field, details, helper] = useField(props);
  let intent: Intent = "none";
  if (details.error) {
    intent = "danger";
  }

  return (
    <FormGroup
      label={props.label}
      intent={intent}
      helperText={details.error ?? props.hint}
    >
      <Select
        isSearchable={true}
        name={field.name}
        options={props.options}
        onChange={(e) => {
          helper.setValue(e?.value);
        }}
      />
    </FormGroup>
  );
};
