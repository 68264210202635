import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import parseJSON from "date-fns/parseJSON";
import format from "date-fns/format";
import { Breadcrumbs, BreadcrumbProps } from "@blueprintjs/core";
import { useSingleContent } from "../../hooks/useContent";

import styles from "./AgHistoryPage.module.css";

export function AgHistoryPage() {
  const params = useParams() as any;
  const history = useHistory();

  const { data: article } = useSingleContent("student_news", params.slug);

  const agHistoryPageBreadcrumbs: BreadcrumbProps[] = [
    { onClick: () => history.push("/students"), text: "Students" },
    {
      onClick: () => history.push("/students/ag-history-news"),
      text: "Ag & History News",
    },
    { text: article?.title ?? "...loading..." },
  ];

  const createdAtDate = parseJSON(article ? article.created_at : 100);

  return (
    <div className={styles.article}>
      <Helmet>
        <title>{`${article?.title}: Ag & History News - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <Breadcrumbs
        className={styles.breadcrumbs}
        items={agHistoryPageBreadcrumbs}
      />
      <header>
        <h1>{article ? article.title : "Loading"}</h1>
        <h2>Ag &amp; History News</h2>
        {article && (
          <div className={styles.postDate}>{format(createdAtDate, "PP")}</div>
        )}
      </header>

      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: article ? article.html : "" }}
      />
    </div>
  );
}
