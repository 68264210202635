import { Route } from "react-router-dom";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import Joyride from "react-joyride";
import { Helmet } from "react-helmet";

import { TeacherMenu } from "../components/TeacherMenu";
import { UserMenu } from "../components/UserMenu";

import styles from "./TeacherDashboard.module.css";

import { LandingPage } from "./teachers/LandingPage";
import { StudentPage } from "./teachers/StudentPage";
import { ResourcesPage } from "./teachers/ResourcesPage";
import { TravelGuidePage } from "./teachers/TravelGuidePage";
import { useEffect, useState } from "react";
import { ActivitiesPage } from "./teachers/ActivitiesPage";
import { NewsPage } from "./teachers/NewsPage";
import { NewsListing } from "./teachers/NewsListing";

export function TeacherDashboardPage() {
  const [showAlphaFirstRun, setShowAlphaFirstRun] = useState(false);
  const alphaFirstRunSteps = [
    {
      target: "#user_menu",
      content:
        "Click here for the user menu, where you can logout, or return to the Teacher's Dashboard from anywhere in the portal.",
    },
    {
      target: "#teacher_management_menu",
      content:
        "View all teacher resources here, as well as tools for student management.",
    },
    {
      target: "#activity_management_menu",
      content: "View all activities submitted by your students.",
    },
  ];

  const onDismissAlphaDialog = () => {
    setShowAlphaFirstRun(false);
    localStorage.setItem("1.0.0-beta_firstrun", "true");
  };

  useEffect(() => {
    const firstRunFlag = localStorage.getItem("1.0.0-beta_firstrun");
    if (firstRunFlag === "true") {
      setShowAlphaFirstRun(false);
    } else {
      setShowAlphaFirstRun(true);
    }
  }, []);
  return (
    <div className="page">
      <Helmet>
        <title>{`Teacher Dashboard - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <div className={styles.dashboard}>
        <TeacherMenu />
        <div className={styles.subPage}>
          <UserMenu />
          <Route exact path="/teachers" component={LandingPage} />
          <Route exact path="/teachers/students" component={StudentPage} />
          <Route exact path="/teachers/resources" component={ResourcesPage} />
          <Route
            exact
            path="/teachers/travel-guide"
            component={TravelGuidePage}
          />
          <Route
            exact
            path="/teachers/activities/:activity"
            component={ActivitiesPage}
          />
          <Route exact path="/teachers/news" component={NewsListing} />
          <Route exact path="/teachers/news/:slug" component={NewsPage} />
        </div>
      </div>
      <Joyride
        run={true}
        continuous={true}
        steps={alphaFirstRunSteps}
        disableOverlay={false}
        disableOverlayClose={true}
        showSkipButton={false}
      />
      <Dialog
        isOpen={showAlphaFirstRun}
        title="Welcome to Tour SD! Portal - Beta Version"
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Welcome to the new Tour SD! Teacher Portal! Pardon our dust as we
            continue to build out the rest of the features.
            <br />
            <br />
          </p>
          <p>
            Stay tuned to the Portal or your email address for further updates!
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="OK" onClick={onDismissAlphaDialog} />
          </div>
        </div>
      </Dialog>
      <footer className="main">
        <div className="copyright">
          Tour SD Portal v2.0.0-beta &copy; 2021 Tour SD. All rights reserved.
        </div>
      </footer>
    </div>
  );
}
