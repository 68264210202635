import { useQuery } from "react-query";
import { AppError } from "../lib/errors";
import { apiGetResources } from "../lib/net";

import { Resource } from "../lib/definitions";

export function useResources(path: string) {
  return useQuery<Resource[], AppError>(
    ["resources", path],
    () => {
      return apiGetResources(path);
    },
    {
      enabled: true,
    }
  );
}
