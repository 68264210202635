import { Callout } from "@blueprintjs/core";
import { AppError, AppErrorCodes } from "../lib/errors";

import styles from "./ErrorAlert.module.css";

interface ErrorAlertProps {
  err: AppError;
}

export default function ErrorAlert(props: ErrorAlertProps) {
  if (props.err.getAppErrorCode() === AppErrorCodes.ValidationError) {
    return (
      <div className={styles.errorAlert}>
        <Callout intent="danger" title="An error occurred.">
          There was a validation error for this operation:
          <code>{props.err.message}</code>
        </Callout>
      </div>
    );
  }

  return (
    <div className={styles.errorAlert}>
      <Callout intent="danger" title="An error occurred.">
        Error Code {props.err.getAppErrorCode()}
      </Callout>
    </div>
  );
}
