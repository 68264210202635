import Avatar from "react-avatar";
import { Popover2 } from "@blueprintjs/popover2";
import { useHistory } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

import styles from "./UserMenu.module.css";
import { Menu, MenuDivider, MenuItem } from "@blueprintjs/core";

export function UserMenu() {
  const { user, logout } = useAuth();
  const history = useHistory();

  let fullName = `${user.first_name} ${user.last_name}`;
  if (user.account_type === "student") {
    fullName = user.user_name;
  }

  return (
    <div className={styles.currentUser} id="user_menu">
      <div className={styles.details}>
        <h1>{fullName}</h1>
        <h2>{user.account_type}</h2>
      </div>
      <Popover2
        modifiers={{
          arrow: {
            enabled: true,
          },
        }}
        placement="bottom-start"
        content={
          <Menu>
            <MenuItem
              text="Student Portal"
              onClick={() => {
                history.push("/students");
              }}
            />
            {user.account_type === "teacher" && (
              <MenuItem
                text="Teacher Portal"
                onClick={() => {
                  history.push("/teachers");
                }}
              />
            )}

            <MenuDivider />
            <MenuItem
              text="Log Out"
              icon="log-out"
              intent="danger"
              onClick={() => {
                logout();
              }}
            />
          </Menu>
        }
      >
        <Avatar
          name={fullName}
          size="50"
          color="#65BBB5"
          round={true}
          style={{
            cursor: "pointer",
          }}
          // backgroundColor="#65BBB5"
          // cursor="pointer"
        />
      </Popover2>
    </div>
  );
}
