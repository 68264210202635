import { useEffect, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import { Button, Callout } from "@blueprintjs/core";

import { AppError, AppErrorCodes } from "../lib/errors";

import { TextFormField } from "./TextFormField";

import styles from "./LoginForm.module.css";
import { apiResetPassword } from "../lib/net";
import { useAuth } from "../hooks/useAuth";

export interface ResetPasswordFormInterface {
  username: string;
  temp_password: string;
  password: string;
  confirm_password: string;
}

export function ResetPasswordForm() {
  const { login } = useAuth();
  const history = useHistory();
  const [errorCode, setErrorCode] = useState<AppErrorCodes | null>(null);

  const params = new URLSearchParams(window.location.search);
  const username = params.get("username");

  const initialFormValues: ResetPasswordFormInterface = {
    username: username ?? "",
    temp_password: "",
    password: "",
    confirm_password: "",
  };

  const onFormSubmit = async (
    values: ResetPasswordFormInterface,
    { setSubmitting }: FormikHelpers<ResetPasswordFormInterface>
  ) => {
    setErrorCode(null);
    try {
      await apiResetPassword(
        values.username,
        values.temp_password,
        values.password
      );

      await login({
        username: values.username,
        password: values.password,
      });

      history.push("/");
    } catch (err) {
      if (err instanceof AppError) {
        setErrorCode(err.getAppErrorCode());
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!initialFormValues.username) {
      history.push("/login");
    }
  }, []);

  return (
    <section className={styles.loginForm}>
      <h1>Reset Temporary Password</h1>

      {errorCode === AppErrorCodes.UserForbidden && (
        <Callout intent="danger" title="Incorrect temp password.">
          Double check the temp password you entered. Also keep in mind that
          temp passwords expire after 7 days.
        </Callout>
      )}

      {errorCode && errorCode !== AppErrorCodes.UserForbidden && (
        <Callout intent="danger" title="An error occurred.">
          Error Code {errorCode}
        </Callout>
      )}

      <Formik initialValues={initialFormValues} onSubmit={onFormSubmit}>
        {({ isSubmitting, submitForm }) => (
          <Form className={styles.form}>
            <div className={styles.field}>
              <TextFormField
                required={true}
                type="password"
                name="temp_password"
                label="Enter Temp Password"
                disabled={isSubmitting}
                hint="Enter the temporary password sent to you via email."
              />
            </div>
            <div className={styles.field}>
              <TextFormField
                required={true}
                type="password"
                name="password"
                label="New Password"
                disabled={isSubmitting}
                hint="Enter a new account password.  Make it secure.  We highly suggest using a password manager."
              />
            </div>
            <div className={styles.field}>
              <TextFormField
                required={true}
                type="password"
                name="confirm_password"
                label="Confirm New Password"
                disabled={isSubmitting}
                hint="Confirm you new password."
              />
            </div>
            <div className={styles.buttons}>
              <Button
                loading={isSubmitting}
                intent="primary"
                large
                onClick={() => {
                  submitForm();
                }}
                text="Set New Password"
              />

              <Button
                large
                onClick={(e: any) => {
                  e.preventDefault();
                  history.push("/login");
                }}
                disabled={isSubmitting}
                text="Cancel"
              />
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
}
