import { Helmet } from "react-helmet";
import { Link, Route } from "react-router-dom";
import classNames from "classnames/bind";

import logo from "../assets/mini_logo.svg";

import { UserMenu } from "../components/UserMenu";

import { LandingPage } from "./students/LandingPage";
import { ActivitiesPage } from "./students/ActivitiesPage";
import { TownPage } from "./students/TownPage";
import { AgHistoryListing } from "./students/AgHistoryListing";
import { AgHistoryPage } from "./students/AgHistoryPage";

import styles from "./StudentDashboard.module.css";

const cx = classNames.bind(styles);

export function StudentDashboardPage() {
  return (
    <div className="page">
      <Helmet>
        <title>{`Student Dashboard - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <div className={styles.dashboard}>
        <span></span>
        <header
          className={cx("header", {
            noPrint: true,
          })}
        >
          <h1 className={styles.logo}>
            <Link to="/students">
              <img src={logo} alt="Tour SD!" />
            </Link>
          </h1>
          <UserMenu />
        </header>

        <div className={styles.subPage}>
          <Route exact path="/students" component={LandingPage} />
          <Route
            exact
            path="/students/ag-history-news"
            component={AgHistoryListing}
          />
          <Route
            exact
            path="/students/ag-history-news/:slug"
            component={AgHistoryPage}
          />
          <Route exact path="/students/towns/:town" component={TownPage} />
          <Route
            exact
            path="/students/activities/:activity"
            component={ActivitiesPage}
          />
        </div>
      </div>
      <footer className="main">
        <div className="copyright">
          Tour SD Portal v2.0.0-beta &copy; 2021 Tour SD. All rights reserved.
        </div>
      </footer>
    </div>
  );
}
