import { Helmet } from "react-helmet";
import styles from "./Login.module.css";

import { RequestPasswordForm } from "../components/RequestPasswordForm";

import logo from "../assets/logo.svg";

export function RequestPassword() {
  return (
    <div className="page">
      <Helmet>
        <title>{`Request New Password - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <div className={styles.loginPage}>
        <h1 className={styles.logo}>
          <img src={logo} alt="Tour SD!" />
          <small>Portal Version 2.0-alpha</small>
        </h1>
        <RequestPasswordForm />
      </div>
    </div>
  );
}
