import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";
import { Breadcrumbs, BreadcrumbProps } from "@blueprintjs/core";

import { useTown } from "../../hooks/useTowns";

import styles from "./TownPage.module.css";

export function TownPage() {
  const [activeTab, setActiveTab] = useState<"history" | "ag">("history");
  const params = useParams() as any;
  const history = useHistory();

  console.log(params);

  const { isLoading, data: town } = useTown(params.town);

  const townPageBreadcrumbs: BreadcrumbProps[] = [
    { onClick: () => history.push("/students"), text: "Students" },
    { text: "Towns" },
    { text: town?.town ?? "...loading..." },
  ];

  useEffect(() => {
    if (!isLoading && town) {
      window.gtag("event", "town", {
        town: town.town,
      });
    }
  }, [isLoading, town]);

  return (
    <div className={styles.townPage}>
      <Helmet>
        <title>{`Road Trip: ${town?.town} - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <header>
        <h1>{town ? town.town : "Loading"}</h1>
        <div className={styles.info}>
          <span>Population: {town?.population}</span>
          <span>Established {town?.established}</span>
        </div>
      </header>
      <Breadcrumbs className={styles.breadcrumbs} items={townPageBreadcrumbs} />
      <div className={styles.tabs}>
        <div
          className={activeTab === "history" ? "active" : undefined}
          onClick={() => setActiveTab("history")}
        >
          History
        </div>
        <div
          className={activeTab === "ag" ? "active" : undefined}
          onClick={() => setActiveTab("ag")}
        >
          Agriculture
        </div>
      </div>

      {activeTab === "ag" && (
        <div className="tab">
          <div className="sidebar">
            {town?.ag_sidebar?.map((module, index) => {
              return (
                <section key={index}>
                  <h1>{module.type}</h1>
                  <div
                    className="module"
                    dangerouslySetInnerHTML={{ __html: module.content }}
                  ></div>
                </section>
              );
            })}
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: town?.agriculture ?? "" }}
          ></div>
          <div className={styles.clear}></div>
        </div>
      )}

      {activeTab === "history" && (
        <div className="tab">
          <div className="sidebar">
            {town?.history_sidebar?.map((module, index) => {
              return (
                <section key={index}>
                  <h1>{module.type}</h1>
                  <div
                    className="module"
                    dangerouslySetInnerHTML={{ __html: module.content }}
                  ></div>
                </section>
              );
            })}
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: town?.history ?? "" }}
          ></div>
          <div className={styles.clear}></div>
        </div>
      )}
    </div>
  );
}
