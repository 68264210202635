import { Helmet } from "react-helmet";
import {
  Spinner,
  Breadcrumbs,
  BreadcrumbProps,
  NonIdealState,
} from "@blueprintjs/core";
import { Link } from "react-router-dom";
import parseJSON from "date-fns/parseJSON";
import format from "date-fns/format";

import { useHistory } from "react-router-dom";
import { useContent } from "../../hooks/useContent";

import styles from "./NewsPage.module.css";

export function NewsListing() {
  const history = useHistory();

  const newsListingBreadcrumbs: BreadcrumbProps[] = [
    { onClick: () => history.push("/teachers"), text: "Teachers" },
    { onClick: () => history.push("/teachers/news"), text: "News" },
  ];

  const { isLoading, data: teacherNews } = useContent("teacher_news");

  return (
    <div className="landingPage">
      <Helmet>
        <title>{`Teacher News - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>

      <header>
        <h1>News</h1>
      </header>

      <Breadcrumbs
        className={styles.breadcrumbs}
        items={newsListingBreadcrumbs}
      />

      <section className="section">
        {isLoading && <Spinner />}
        {!isLoading && teacherNews?.length === 0 && (
          <NonIdealState
            title="No published news"
            icon="document"
            description="Check back later for more teacher news."
            className={styles.empty}
          />
        )}
        <div className={styles.articles}>
          {teacherNews?.map((c) => {
            const createdAtDate = parseJSON(c.created_at!);
            return (
              <div className={styles.articleTeaser} key={c.id}>
                <header>
                  <h1>
                    <Link to={`/teachers/news/${c.slug}`}>{c.title}</Link>
                  </h1>
                  <div className={styles.postDate}>
                    {format(createdAtDate, "PPpp")}
                  </div>
                </header>

                <p>{c.teaser}</p>
                <Link
                  className={styles.readMore}
                  to={`/teachers/news/${c.slug}`}
                >
                  Read More
                </Link>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}
