import { useState } from "react";
import { useQueryClient } from "react-query";
import * as yup from "yup";

import XLSX from "xlsx";
import { Formik, Form, FormikHelpers } from "formik";

import { SelectFormField } from "./SelectFormField";
import ErrorAlert from "./ErrorAlert";

import { AppError, AppErrorCodes } from "../lib/errors";
import { StudentGroup, Student } from "../lib/definitions";
import { apiImportStudents } from "../lib/net";

import styles from "./ImportStudentsForm.module.css";
import {
  Dialog,
  Classes,
  FormGroup,
  FileInput,
  Label,
  Tag,
  Button,
} from "@blueprintjs/core";

interface ImportStudentsFormProps {
  showForm: boolean;
  onClose: () => void;
  groups: StudentGroup[];
}

interface ImportStudentsFormValues {
  group_id: string;
  students: Student[];
}

const validationSchema = yup.object({
  students: yup.array().min(1, "You must import at least one student"),
  group_id: yup.string().required("A student group is required."),
});

export function ImportStudentsForm(props: ImportStudentsFormProps) {
  const queryClient = useQueryClient();

  const [error, setError] = useState<AppError | null>(null);
  const initialFormValues: ImportStudentsFormValues = {
    group_id: "",
    students: [],
  };

  const processFile = async (files: FileList) => {
    setError(null);

    if (files.length) {
      try {
        const file = files[0];
        const data = await file.arrayBuffer();

        const book = XLSX.read(data);

        const sheetName = book.SheetNames[0];
        const sheet = book.Sheets[sheetName];

        const importObject = XLSX.utils.sheet_to_json(sheet);

        const students: Student[] = [];

        importObject.forEach((o: any) => {
          if (o["Username"].length > 0 && o["Password"].length > 5) {
            students.push({
              username: o["Username"],
              password: o["Password"],
              group_id: "",
              id: "",
              teacher_id: "",
            });
          }
        });

        return students;
      } catch (err) {
        const newError = new AppError(AppErrorCodes.DataParsingError, err);
        setError(newError);
      }
    }

    return [];
  };

  const onFormSubmit = async (
    values: ImportStudentsFormValues,
    { setSubmitting }: FormikHelpers<ImportStudentsFormValues>
  ) => {
    setSubmitting(true);
    setError(null);
    try {
      if (values.students.length === 0) {
        throw new AppError(
          AppErrorCodes.ValidationError,
          new Error("You must import at least one student")
        );
      }

      await apiImportStudents(values.group_id, values.students);

      queryClient.invalidateQueries(`students-${values.group_id}`);

      props.onClose();
    } catch (err) {
      if (err instanceof AppError) {
        setError(err);
      }
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      isOpen={props.showForm}
      title="Import Students"
      isCloseButtonShown={false}
    >
      {error && <ErrorAlert err={error} />}

      <Formik
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, setFieldValue, values, submitForm }) => (
          <Form className={styles.form}>
            <div className={Classes.DIALOG_BODY}>
              <p>
                You can bulk import students into Tour SD! by selecting an Excel
                .xlsx or .csv file in the file picker below. Submitted files
                must conform to a certain template, to download the template,{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    process.env.PUBLIC_URL +
                    "/templates/student_import_template.csv"
                  }
                >
                  click here
                </a>
                .
              </p>
              <div className={styles.filePickerField}>
                <FormGroup label="Upload a File *">
                  <FileInput
                    fill
                    inputProps={{
                      accept: ".xlsx, .csv",
                    }}
                    text="Select a .xlsx or .csv file"
                    onInputChange={async (e) => {
                      const files = e.currentTarget.files;
                      if (files) {
                        const students = await processFile(files);
                        setFieldValue("students", students);
                      }
                    }}
                  />
                </FormGroup>
              </div>
              <div className="field">
                <SelectFormField
                  required
                  name="group_id"
                  label="Student Group"
                  placeholder="Select a Group"
                  options={props.groups.map((group) => {
                    return {
                      label: group.name,
                      value: group.id,
                    };
                  })}
                />
              </div>
              <div>
                <Label>Students to Import</Label>
                <div style={{ marginTop: "10px" }}>
                  <ul
                    style={{ listStyle: "none", marginLeft: 0, paddingLeft: 0 }}
                  >
                    {values.students.map((student, index) => {
                      return (
                        <li key={index} style={{ marginBottom: 5 }}>
                          <Tag intent="success">Valid</Tag>{" "}
                          <strong>{student.username}</strong> -{" "}
                          {student.password}
                        </li>
                      );
                    })}
                    {values.students.length === 0 && <li>None</li>}
                  </ul>
                </div>
              </div>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  disabled={isSubmitting}
                  onClick={(e: any) => {
                    e.preventDefault();
                    props.onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={values.students.length === 0}
                  loading={isSubmitting}
                  intent="primary"
                  text={`Import ${values.students.length} Students`}
                  onClick={() => {
                    submitForm();
                  }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
