import { useField, FieldHookConfig } from "formik";
import { FormGroup, HTMLSelect, Intent } from "@blueprintjs/core";

export interface FormFieldProps {
  label: string;
  hint?: string;
  options?: {
    label: string;
    value: any;
  }[];
}

export const SelectFormField = (
  props: FormFieldProps & FieldHookConfig<string>
) => {
  const [field, details] = useField(props);
  let intent: Intent = "none";
  if (details.error) {
    intent = "danger";
  }

  return (
    <FormGroup
      label={props.label}
      intent={intent}
      helperText={details.error ?? props.hint}
    >
      <HTMLSelect
        name={field.name}
        onChange={field.onChange}
        value={field.value}
        fill
        required={props.required}
        disabled={props.disabled}
      >
        <option value="">{props.placeholder}</option>
        {props.options?.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </HTMLSelect>
    </FormGroup>
  );
};
