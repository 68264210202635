import { Button, NonIdealState, Spinner } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import parseJSON from "date-fns/parseJSON";
import format from "date-fns/format";

import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useContent } from "../../hooks/useContent";

import styles from "./LandingPage.module.css";

export function LandingPage() {
  const { user } = useAuth();
  const history = useHistory();

  const { isLoading: loadingTeacherNews, data: teacherNews } = useContent(
    "teacher_news",
    3
  );

  return (
    <div className="landingPage">
      <header>
        <h1>Good Morning, {user.first_name}!</h1>
      </header>

      <section className="section">
        <h1>What's New from Tour SD!</h1>
        {loadingTeacherNews && <Spinner />}

        <div className={styles.articles}>
          {teacherNews?.length === 0 && (
            <NonIdealState
              title="No published news"
              icon="document"
              description="Check back later for more teacher news."
              className={styles.empty}
            />
          )}
          {teacherNews?.map((c) => {
            const createdAtDate = parseJSON(c.created_at!);
            return (
              <div className={styles.article} key={c.id}>
                <header>
                  <h1>
                    <Link to={`/teachers/news/${c.slug}`}>{c.title}</Link>
                  </h1>
                  <div className={styles.postDate}>
                    {format(createdAtDate, "PPpp")}
                  </div>
                </header>

                <p>{c.teaser}</p>
                <Link
                  className={styles.readMore}
                  to={`/teachers/news/${c.slug}`}
                >
                  Read More
                </Link>
              </div>
            );
          })}
        </div>
        <div className={styles.buttons}>
          <Button
            intent="primary"
            onClick={() => history.push("/teachers/news")}
            text="More News"
          />
        </div>
      </section>

      <section className="section">
        <h1>Recent Activity</h1>
        <NonIdealState
          title="Coming Soon!"
          icon="build"
          description="View a list of the most recent student activity."
        />
      </section>
    </div>
  );
}
