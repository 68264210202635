const SDMapPath = [
  new google.maps.LatLng(45.958788, -104.040527),
  new google.maps.LatLng(45.943511, -96.569824),
  new google.maps.LatLng(45.844108, -96.591797),
  new google.maps.LatLng(45.775186, -96.646729),
  new google.maps.LatLng(45.713851, -96.745605),
  new google.maps.LatLng(45.637087, -96.811523),
  new google.maps.LatLng(45.444717, -96.70166),
  new google.maps.LatLng(45.406164, -96.569824),
  new google.maps.LatLng(45.367584, -96.49292),
  new google.maps.LatLng(45.290347, -96.470947),
  new google.maps.LatLng(43.53262, -96.448975),
  new google.maps.LatLng(43.508721, -96.580811),
  new google.maps.LatLng(43.444943, -96.580811),
  new google.maps.LatLng(43.381098, -96.525879),
  new google.maps.LatLng(43.301196, -96.536865),
  new google.maps.LatLng(43.253205, -96.547852),
  new google.maps.LatLng(43.213183, -96.470947),
  new google.maps.LatLng(43.100983, -96.459961),
  new google.maps.LatLng(42.964463, -96.503906),
  new google.maps.LatLng(42.884015, -96.558838),
  new google.maps.LatLng(42.803462, -96.591797),
  new google.maps.LatLng(42.763146, -96.635742),
  new google.maps.LatLng(42.682435, -96.591797),
  new google.maps.LatLng(42.603642, -96.495667),
  new google.maps.LatLng(42.54701, -96.479187),
  new google.maps.LatLng(42.516651, -96.48468),
  new google.maps.LatLng(42.494378, -96.459961),
  new google.maps.LatLng(42.4802, -96.503906),
  new google.maps.LatLng(42.5207, -96.556091),
  new google.maps.LatLng(42.514626, -96.624756),
  new google.maps.LatLng(42.565219, -96.668701),
  new google.maps.LatLng(42.61577, -96.723633),
  new google.maps.LatLng(42.6683, -96.712646),
  new google.maps.LatLng(42.666281, -96.781311),
  new google.maps.LatLng(42.70666, -96.808777),
  new google.maps.LatLng(42.736926, -96.907654),
  new google.maps.LatLng(42.720786, -96.951599),
  new google.maps.LatLng(42.761129, -96.987305),
  new google.maps.LatLng(42.771211, -97.119141),
  new google.maps.LatLng(42.815551, -97.223511),
  new google.maps.LatLng(42.865899, -97.319641),
  new google.maps.LatLng(42.849793, -97.46521),
  new google.maps.LatLng(42.865899, -97.775574),
  new google.maps.LatLng(42.85986, -97.877197),
  new google.maps.LatLng(42.785323, -97.929382),
  new google.maps.LatLng(42.763146, -98.041992),
  new google.maps.LatLng(42.956423, -98.459473),
  new google.maps.LatLng(43.044805, -98.536377),
  new google.maps.LatLng(43.012681, -98.690186),
  new google.maps.LatLng(42.996612, -104.0625),
  new google.maps.LatLng(45.042478, -104.0625),
  new google.maps.LatLng(45.943511, -104.073486),
];

export default SDMapPath;
