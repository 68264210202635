import { useQuery } from "react-query";
import { AppError } from "../lib/errors";
import { apiGetStudentGroups, apiGetStudents } from "../lib/net";

import { Student, StudentGroup } from "../lib/definitions";

export function useStudents(groupId: string) {
  return useQuery<Student[], AppError>(`students-${groupId}`, () => {
    return apiGetStudents(groupId);
  });
}

export function useStudentGroups() {
  return useQuery<StudentGroup[], AppError>("studentgroups", () => {
    return apiGetStudentGroups();
  });
}
