import { Button, Classes, Dialog } from "@blueprintjs/core";
import { fabric } from "fabric";
import parseJSON from "date-fns/parseJSON";
import formatRelative from "date-fns/formatRelative";
import { ActivityData, JournalPost, Student } from "../lib/definitions";

import { Post } from "../activities/OnlineJournal";
import { TimelineDisplay, Timeline } from "../activities/TimelineBuilder";

import mapBuilderBlankImage from "../assets/mapbuilder_blank.png";
import { useCallback, useEffect, useState } from "react";
import { MapCanvas } from "../activities/MapBuilder";

interface ActivityViewerProps {
  showDialog: boolean;
  onClose: () => void;
  student: Student;
  activity: ActivityData;
}

function getActivityRenderer(activity: ActivityData) {
  if (activity.activity === "timeline-builder") {
    const timeline = activity.data as Timeline;
    return {
      title: "Timeline Builder",
      component: <TimelineDisplay timeline={timeline} />,
    };
  }

  if (activity.activity === "map-builder") {
    const mapData = activity.data;
    console.log(mapData);
    return {
      title: "Map Builder",
      component: <MapCanvasRender data={mapData} />,
    };
  }

  if (activity.activity === "online-journal") {
    const post = activity.data as JournalPost;
    return {
      title: "Online Journal",
      component: <Post post={post} />,
    };
  }

  return {
    title: "Unknown Activity",
    component: <div>Unknown activity - no renderer available</div>,
  };
}

function MapCanvasRender(props: { data: any }) {
  const [canvas, setCanvas] = useState<fabric.StaticCanvas | undefined>();
  const newCanvas = useCallback(() => {
    if (canvas) {
      canvas.dispose();
    }

    const newCanvas = new fabric.StaticCanvas("mapbuilder_canvas", {
      interactive: false,
    });

    newCanvas.setBackgroundImage(
      mapBuilderBlankImage,
      newCanvas.renderAll.bind(newCanvas),
      {
        width: newCanvas.getWidth(),
        height: newCanvas.getHeight(),
        originX: "left",
        originY: "top",
      }
    );

    newCanvas.loadFromJSON(props.data.data, () => {
      console.log("saved map loaded");
    });
    setCanvas(newCanvas);
  }, [canvas, props.data.data]);

  useEffect(() => {
    if (!canvas) {
      newCanvas();
    }
  }, [canvas, newCanvas]);

  return (
    <div>
      <h1>{props.data.name}</h1>
      <MapCanvas />
    </div>
  );
}

export function ActivityViewer(props: ActivityViewerProps) {
  const activity = getActivityRenderer(props.activity);

  const createdAtDate = parseJSON(props.activity.created_at!);

  return (
    <Dialog
      isOpen={props.showDialog}
      isCloseButtonShown={false}
      title={`Activity Viewer - ${activity.title}`}
      style={{
        width: 900,
      }}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>Created by {props.student.username}</p>
        <p>Created {formatRelative(createdAtDate, Date.now())}</p>
        {/* <p>Last Updated at {props.activity.updated_at}</p> */}
        <div
          style={{
            marginTop: 40,
          }}
        >
          {activity.component}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Close" onClick={props.onClose} />
        </div>
      </div>
    </Dialog>
  );
}
