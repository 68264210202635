import { Helmet } from "react-helmet";
import { Spinner, Breadcrumbs, BreadcrumbProps } from "@blueprintjs/core";

import { useParams, useHistory } from "react-router-dom";
import parseJSON from "date-fns/parseJSON";
import format from "date-fns/format";

import { useSingleContent } from "../../hooks/useContent";

import styles from "./NewsPage.module.css";

export function NewsPage() {
  const params = useParams() as any;
  const history = useHistory();
  const { isLoading, data, error } = useSingleContent(
    "teacher_news",
    params.slug
  );

  const newsPageBreadcrumbs: BreadcrumbProps[] = [
    { onClick: () => history.push("/teachers"), text: "Teachers" },
    { onClick: () => history.push("/teachers/news"), text: "News" },
    { text: data?.title ?? "...loading..." },
  ];

  const createdAtDate = parseJSON(data?.created_at!);

  return (
    <div className="landingPage">
      <Helmet>
        <title>{`${data?.title}: Teacher News - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>

      <header>
        <h1>News</h1>
      </header>

      <Breadcrumbs className={styles.breadcrumbs} items={newsPageBreadcrumbs} />

      <section className="section">
        {isLoading && <Spinner />}
        {!isLoading && error && <div>{error.message}</div>}
        {!isLoading && data && (
          <div className={styles.article}>
            <header>
              <h1>{data.title}</h1>
              <div className={styles.postDate}>
                Posted on {format(createdAtDate, "PPpp")}
              </div>
            </header>

            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: data.html }}
            />
          </div>
        )}
      </section>
    </div>
  );
}
