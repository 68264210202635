export enum AppErrorCodes {
  Unknown,
  NetworkError,
  UserUnauthorized,
  UserForbidden,
  ValidationError,
  DataParsingError,
}

export class AppError {
  private error: Error;
  private code: AppErrorCodes;

  public message: string;

  constructor(code: AppErrorCodes, err: any) {
    if (err.response) {
      this.message = `${err.message} - ${err.response.data}`;
    } else {
      this.message = `${err.message}`;
    }

    this.code = code;
    this.error = err;
  }

  public getAppErrorCode(): AppErrorCodes {
    return this.code;
  }

  public getError(): Error {
    return this.error;
  }
}
