import { useQuery } from "react-query";
import { ActivityData } from "../lib/definitions";
import { AppError } from "../lib/errors";
import { apiGetActivityData, getAllActivityData } from "../lib/net";

export function useActivityData(activitySlug: string) {
  return useQuery<ActivityData[], AppError>(
    [
      "student-activity",
      {
        activity: activitySlug,
      },
    ],
    () => {
      return apiGetActivityData(activitySlug);
    },
    {
      enabled: true,
    }
  );
}

export function useAllActivityData(activitySlug: string) {
  return useQuery<ActivityData[], AppError>(
    [
      "all-activity",
      {
        activity: activitySlug,
      },
    ],
    () => {
      return getAllActivityData(activitySlug);
    },
    {
      enabled: true,
    }
  );
}
