import { useState } from "react";
import { Button } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";

import SDMapPath from "../constants/map";

import { useTowns } from "../hooks/useTowns";

import styles from "./RoadTripMap.module.css";

export function RoadTripMap() {
  const [infoWindows, setInfoWindows] = useState<string[]>([]);
  const history = useHistory();

  const { data } = useTowns();

  const center = {
    lat: 44.3646,
    lng: -100.239787,
  };

  const zoom = 7;

  const onTownClick = (town: string) => {
    history.push(`/students/towns/${town}`);
  };

  const onToggleInfoWindow = (slug: string) => {
    const windows = [...infoWindows];
    if (windows.includes(slug)) {
      windows.splice(windows.indexOf(slug), 1);
    } else {
      windows.push(slug);
    }

    setInfoWindows(windows);
  };

  return (
    <div className={styles.container}>
      <div className={styles.map}>
        <GoogleMap
          mapContainerClassName={styles.map}
          center={center}
          zoom={zoom}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
          }}
        >
          <Polyline
            path={SDMapPath}
            options={{
              strokeColor: "#3d4954",
            }}
          />
          {data?.map((town) => {
            return (
              <Marker
                key={town.slug}
                position={{
                  lat: town.lat,
                  lng: town.lng,
                }}
                icon={
                  "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|3d4954|FFFFFF"
                }
                onClick={() => onToggleInfoWindow(town.slug)}
              >
                {infoWindows.includes(town.slug) && (
                  <InfoWindow
                    onCloseClick={() => onToggleInfoWindow(town.slug)}
                  >
                    <section className={styles.infoWindow}>
                      <h1>{town.name}</h1>
                      <div className="info">
                        <strong>Pop.: </strong>
                        {town.population}
                        <br />
                        <strong>Est.: </strong>
                        {town.established}
                      </div>

                      <Button
                        minimal
                        outlined
                        large
                        key={town.slug}
                        onClick={() => onTownClick(town.slug)}
                      >
                        Take a trip to {town.name}
                      </Button>
                    </section>
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        </GoogleMap>
      </div>
      <div className={styles.townListing}>
        <p>
          Click on the icons or areas of the map to view content and activities
          for each town, or select the buttons below.
        </p>
        <div className={styles.mapLinks}>
          {data?.map((town) => {
            return (
              <Button
                minimal
                outlined
                large
                key={town.slug}
                onClick={() => onTownClick(town.slug)}
              >
                {town.name}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
