import classNames from "classnames/bind";

import { Link } from "react-router-dom";
import { RoadTripMap } from "../../components/RoadTripMap";
import { AgHistoryNews } from "../../components/AgHistoryNews";

import timelineIcon from "../../assets/timelineIcon.jpg";
import TravelGuideThumbnailImage from "../../assets/TourSDTravelGuide.jpg";
import mapIcon from "../../assets/mapIcon.jpg";

import styles from "./LandingPage.module.css";
import { apiDownloadTravelGuide } from "../../lib/net";
import { Toaster } from "@blueprintjs/core";
import { AppError } from "../../lib/errors";

const cx = classNames.bind(styles);

export function LandingPage() {
  const onTravelGuideDownload = async () => {
    const toaster = Toaster.create();

    try {
      const key = toaster.show({
        message: "Downloading Travel Guide...",
        intent: "success",
        timeout: 0,
      });
      await apiDownloadTravelGuide();
      toaster.dismiss(key);
    } catch (err) {
      toaster.clear();
      toaster.show({
        message:
          "There was a problem trying to download this resource: " +
          (err as AppError).message,
        intent: "danger",
      });
    }
  };

  return (
    <div className={styles.landingPage}>
      <header>
        <h1>Welcome to Tour SD!</h1>
      </header>
      <div className={styles.columns}>
        <div className="contentCol">
          <RoadTripMap />
          <AgHistoryNews />
        </div>
        <div className={styles.sidebarCol}>
          <Link
            to="/students/activities/timeline-builder"
            className={styles.activityBtn}
          >
            <img src={timelineIcon} alt="timeline" />
            <h2>
              Timeline <span>It!</span>
            </h2>
          </Link>
          <Link
            to="/students/activities/map-builder"
            className={styles.activityBtn}
          >
            <img src={mapIcon} alt="map" />
            <h2>
              Map <span>It!</span>
            </h2>
          </Link>
          <Link
            to="/students/activities/online-journal"
            className={styles.activityBtn}
          >
            <img src={mapIcon} alt="map" />
            <h2>
              Write <span>It!</span>
            </h2>
          </Link>
          <div
            onClick={onTravelGuideDownload}
            className={cx({
              activityBtn: true,
              travelGuide: true,
            })}
          >
            <img src={TravelGuideThumbnailImage} alt="TourSD! Travel Guide" />
            <h2>
              View <span>Tour Guide</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
