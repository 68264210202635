import axios from "axios";

import { AppErrorCodes, AppError } from "./errors";
import { Credentials, UserToken, User } from "../hooks/useAuth";

import {
  Student,
  Resource,
  StudentGroup,
  Town,
  TownSnippet,
  ActivityData,
  TeacherOption,
  Content,
} from "../lib/definitions";

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/";

const handleError = (err: any): AppError => {
  console.log("handling error", err);

  if (err.response) {
    if (err.response.status === 403) {
      return new AppError(AppErrorCodes.UserForbidden, err);
    }

    if (err.response.status === 401) {
      return new AppError(AppErrorCodes.UserUnauthorized, err);
    }

    if (err.response.status === 400) {
      console.log(err.response);
      return new AppError(AppErrorCodes.ValidationError, err);
    }

    if (
      err.response.status === 500 &&
      err.response.data.error == "invalid_grant"
    ) {
      err.message = err.response.data.error;
      err.response.data = err.response.data.message;

      return new AppError(AppErrorCodes.UserUnauthorized, err);
    }
  }

  return new AppError(AppErrorCodes.NetworkError, err);
};

export const apiLogin = async (
  credentials: Credentials
): Promise<UserToken> => {
  try {
    const url = API_URL + "user/login";
    const res = await axios.post(url, credentials);

    return res.data as UserToken;
  } catch (err: any) {
    const appErr = handleError(err);
    throw appErr;
  }
};

export const apiRefreshToken = async (
  refreshToken: string
): Promise<UserToken> => {
  try {
    const url = API_URL + "user/refresh";
    const res = await axios.post(url, {
      refresh_token: refreshToken,
    });

    return res.data as UserToken;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiRequestPasswordReset = async (email: string): Promise<void> => {
  try {
    const url = API_URL + "user/requestpassword";
    await axios.post(url, {
      username: email,
    });
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiResetPassword = async (
  username: string,
  tempPassword: string,
  newPassword: string
): Promise<void> => {
  try {
    const url = API_URL + "user/resetpassword";
    await axios.post(url, {
      username,
      tempPassword,
      newPassword,
    });
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiUser = async (token: UserToken): Promise<User> => {
  try {
    const url = API_URL + "user/";
    const res = await axios.get(url, {
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`,
        AccountType: token.account_type,
      },
    });

    return res.data as User;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiGetStudents = async (groupId: string): Promise<any> => {
  try {
    const url = API_URL + "students/groups/" + groupId + "/students";
    const res = await axios.get(url);

    return res.data;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiGetStudentGroups = async (): Promise<StudentGroup[]> => {
  try {
    const url = API_URL + "students/groups";
    const res = await axios.get(url);

    return res.data as StudentGroup[];
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiAddStudent = async (student: Student): Promise<string> => {
  try {
    const url = `${API_URL}students/groups/${student.group_id}/students`;
    const res = await axios.post(url, student);

    return res.data as string;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiImportStudents = async (
  groupId: string,
  students: Student[]
): Promise<string[]> => {
  try {
    const url = `${API_URL}students/groups/${groupId}/students/import`;
    const res = await axios.post(url, students);

    return res.data as string[];
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiAddStudentGroup = async (
  group: StudentGroup
): Promise<string> => {
  try {
    const url = API_URL + "students/groups";
    const res = await axios.post(url, group);

    return res.data as string;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiDeleteStudentGroup = async (
  group: StudentGroup
): Promise<void> => {
  try {
    const url = API_URL + "students/groups/" + group.id;
    await axios.delete(url);
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiDeleteStudent = async (
  groupId: string,
  studentId: string
): Promise<void> => {
  try {
    const url = `${API_URL}students/groups/${groupId}/students/${studentId}`;
    await axios.delete(url);
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiGetResources = async (path: string): Promise<Resource[]> => {
  try {
    const url = API_URL + "resources/files";
    const res = await axios.post(url, {
      path: path,
    });

    return res.data as Resource[];
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiDownloadResource = async (
  path: string,
  fileName: string
): Promise<void> => {
  try {
    const url = API_URL + "resources/files/download";

    const res = await axios.post(
      url,
      {
        path: path,
      },
      {
        responseType: "blob",
      }
    );

    const downloadurl = window.URL.createObjectURL(
      new Blob([res.data as Blob])
    );
    const link = document.createElement("a");
    link.href = downloadurl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    window.gtag("event", "download", {
      category: "teacher_resources",
      file: path + fileName,
    });
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiDownloadTravelGuide = async (): Promise<void> => {
  try {
    const url = API_URL + "resources/files/downloadTravelGuide";
    const res = await axios.get(url, {
      responseType: "blob",
    });

    const downloadurl = window.URL.createObjectURL(
      new Blob([res.data as Blob])
    );
    const link = document.createElement("a");
    link.href = downloadurl;
    link.setAttribute("download", "TourSD Travel Guide.pdf");
    document.body.appendChild(link);
    link.click();

    window.gtag("event", "download", {
      category: "teacher_resources",
      file: "TourSD Travel Guide.pdf",
    });
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiGetTowns = async (): Promise<TownSnippet[]> => {
  try {
    const url = API_URL + "town/";
    const res = await axios.get<TownSnippet[]>(url);
    return res.data;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiGetTown = async (slug: string): Promise<Town> => {
  try {
    const url = API_URL + "town/" + slug;
    const res = await axios.get<Town>(url);
    return res.data;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiGetActivityData = async (
  activitySlug: string
): Promise<ActivityData[]> => {
  try {
    const url = API_URL + `activity/${activitySlug}/student`;
    const res = await axios.get<ActivityData[]>(url);
    return res.data;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const getAllActivityData = async (
  activitySlug: string
): Promise<ActivityData[]> => {
  try {
    const url = API_URL + `activity/${activitySlug}`;
    const res = await axios.get<ActivityData[]>(url);
    return res.data;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiSaveActivityData = async (
  activitySlug: string,
  activity: ActivityData
): Promise<string> => {
  try {
    const url = API_URL + `activity/${activitySlug}`;
    const res = await axios.post<string>(url, activity);
    return res.data;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiPutActivityData = async (
  activitySlug: string,
  activity: ActivityData
): Promise<void> => {
  try {
    const url = API_URL + `activity/${activitySlug}/${activity.id}`;
    await axios.put(url, activity);
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiGetTeacherOptions = async () => {
  try {
    const url = API_URL + `user/availableteachers`;
    const res = await axios.get<TeacherOption[]>(url);
    return res.data;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiGetContent = async (
  type: string,
  count?: number
): Promise<Content[]> => {
  try {
    let url = API_URL + `content/${type}`;
    if (count) {
      url += `?count=${count}`;
    }

    const res = await axios.get<Content[]>(url);
    return res.data;
  } catch (err: any) {
    throw handleError(err);
  }
};

export const apiGetSingleContent = async (
  type: string,
  slug: string
): Promise<Content> => {
  try {
    const url = API_URL + `content/${type}/${slug}`;
    const res = await axios.get<Content>(url);
    return res.data;
  } catch (err: any) {
    throw handleError(err);
  }
};
