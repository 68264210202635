import { Dialog, Classes, Button } from "@blueprintjs/core";

interface DeleteConfirmationDialogProps {
  showForm: boolean;
  itemName?: string;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export function DeleteConfirmationDialog(props: DeleteConfirmationDialogProps) {
  return (
    <Dialog
      isOpen={props.showForm}
      title="Delete Confirmation"
      isCloseButtonShown={false}
    >
      <div className={Classes.DIALOG_BODY}>
        Are you sure you want to delete {props.itemName}?
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={props.onClose} />
          <Button intent="danger" text="Delete" onClick={props.onConfirm} />
        </div>
      </div>
    </Dialog>
  );
}
