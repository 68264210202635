import { useQuery } from "react-query";
import { TeacherOption } from "../lib/definitions";
import { AppError } from "../lib/errors";
import { apiGetTeacherOptions } from "../lib/net";

export function useTeacherOptions() {
  return useQuery<TeacherOption[], AppError>(
    "teacher_options",
    () => {
      return apiGetTeacherOptions();
    },
    {
      enabled: true,
    }
  );
}
