import TravelGuideThumbnailImage from "../../assets/TourSDTravelGuide.jpg";
import { Helmet } from "react-helmet";
import { Toaster } from "@blueprintjs/core";

import { AppError } from "../../lib/errors";
import { apiDownloadTravelGuide } from "../../lib/net";

import styles from "./TravelGuidePage.module.css";

export function TravelGuidePage() {
  const onTravelGuideDownload = async () => {
    const toaster = Toaster.create();

    try {
      const key = toaster.show({
        message: "Downloading Travel Guide...",
        intent: "success",
        timeout: 0,
      });
      await apiDownloadTravelGuide();
      toaster.dismiss(key);
    } catch (err) {
      toaster.clear();

      toaster.show({
        message: "There was a problem trying to download this resource: " + (err as AppError).message,
        intent: "danger",
      });
    }
  };
  return (
    <div className="landingPage">
      <Helmet>
        <title>{`Student Management - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <header>
        <h1>TourSD! Travel Guide</h1>
      </header>
      <section className="section">
        <h1>Download the TourSD! Travel Guide</h1>
        <p>Travel Guide is unavailable at this time.</p>
      </section>
    </div>
  );
}
