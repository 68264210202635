import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from "react";
import axios from "axios";

import {
  apiLogin,
  apiRefreshToken,
  apiUser,
  apiRequestPasswordReset,
} from "../lib/net";

export interface UserToken {
  expires_in: number;
  expires_at: number;
  access_token: string;
  refresh_token: string;
  token_type: string;
  account_type: string;
}

export interface User {
  first_name: string;
  last_name: string;
  user_name: string;
  account_type: string;
  id: string;
  reset_password: boolean;
}

export interface Credentials {
  username: string;
  password: string;
  teacher?: string;
}

interface AuthContextInterface {
  login: (credentials: Credentials) => Promise<void>;
  requestPassword: (email: string) => Promise<void>;
  logout: () => void;
  user: User;
  isLoggedIn: boolean;
  isReady: boolean;
}

const initialUser: User = {
  first_name: "",
  last_name: "",
  user_name: "",
  account_type: "",
  id: "",
  reset_password: false,
};

export const AuthContext = createContext<AuthContextInterface>({
  login: async (credentials: Credentials) => {},
  requestPassword: async (email: string) => {},
  logout: () => {},
  user: initialUser,
  isLoggedIn: false,
  isReady: false,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState(initialUser);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const refreshToken = async (oldToken: UserToken): Promise<UserToken> => {
    const refreshToken = oldToken.refresh_token;
    const newToken = await apiRefreshToken(refreshToken);
    newToken.account_type = oldToken.account_type;
    newToken.expires_at =
      Math.floor(Date.now() / 1000) + (newToken.expires_in - 30);
    return newToken;
  };

  const getToken = (): UserToken | null => {
    const value = localStorage.getItem("user_token");
    if (value) {
      return JSON.parse(value) as UserToken;
    }

    return null;
  };

  const login = useCallback(async (credentials: Credentials) => {
    try {
      const userToken = await apiLogin(credentials);

      if (credentials.teacher) {
        userToken.account_type = "student";
      } else {
        userToken.account_type = "teacher";
      }

      const loggedInUser = await apiUser(userToken);

      window.gtag("set", process.env.REACT_GA_ID!, {
        user_id: loggedInUser.id,
        userName: loggedInUser.user_name,
        accountType: loggedInUser.account_type,
      });

      userToken.expires_at =
        Math.floor(Date.now() / 1000) + (userToken.expires_in - 30);

      localStorage.setItem("user_token", JSON.stringify(userToken));

      console.log(loggedInUser);

      await setUser(loggedInUser);
      await setIsLoggedIn(true);

      console.log("user should be logged in");
    } catch (err) {
      console.log("login failed", err);
      throw err;
    }
  }, []);

  const requestPassword = useCallback(async (email: string) => {
    try {
      await apiRequestPasswordReset(email);
    } catch (err) {
      throw err;
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("user_token");

    setIsLoggedIn(false);
    setUser(initialUser);
  }, []);

  const interceptors = useCallback(() => {
    // set up axios interceptors
    console.log("setting up interceptors");
    axios.interceptors.request.use(
      async (req) => {
        let currentToken = getToken();

        if (currentToken) {
          const now = Math.floor(Date.now() / 1000);

          if (now >= currentToken.expires_at) {
            // token is expired
            console.log("token is expired");

            if (currentToken.account_type === "student") {
              // just log out
              logout();
            } else {
              if (req.url && req.url?.indexOf("/api/v1/user/refresh") === -1) {
                console.log("token is expired, refreshing...");
                console.log(req);

                const newToken = await refreshToken(currentToken);
                localStorage.setItem("user_token", JSON.stringify(newToken));

                currentToken = newToken;
                console.log(currentToken);
              }
            }
          }

          req.headers = {
            Authorization: `${currentToken.token_type} ${currentToken.access_token}`,
            AccountType: currentToken.account_type,
          };
        }

        return req;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        const currentToken = getToken();
        if (currentToken) {
          if (err.response.status === 401) {
            console.log("token is unauthorized");
            logout();
          }
        }

        return Promise.reject(err);
      }
    );
  }, [logout]);

  const init = useCallback(async () => {
    console.log("calling init...");
    // get saved token if exists
    try {
      const value = localStorage.getItem("user_token");
      if (value) {
        let savedToken = JSON.parse(value) as UserToken;
        const now = Math.floor(Date.now() / 1000);

        if (
          now >= savedToken.expires_at &&
          savedToken.account_type === "teacher"
        ) {
          console.log("token is expired, refreshing...");
          // token is expired
          const newToken = await refreshToken(savedToken);

          localStorage.setItem("user_token", JSON.stringify(newToken));

          savedToken = newToken;
        }

        const loggedInUser = await apiUser(savedToken);

        window.gtag("set", process.env.REACT_GA_ID!, {
          user_id: loggedInUser.id,
          userName: loggedInUser.user_name,
          accountType: loggedInUser.account_type,
        });

        await setUser(loggedInUser);
        await setIsLoggedIn(true);
      }
    } catch (err) {
      console.error(err);
      localStorage.removeItem("user_token");
    }

    interceptors();
    setIsReady(true);
  }, []);

  useEffect(() => {
    init().catch(console.error);
  }, [init]);

  return (
    <AuthContext.Provider
      value={{
        login,
        requestPassword,
        logout,
        user,
        isLoggedIn,
        isReady,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
