import { useState } from "react";
import {
  Button,
  FormGroup,
  InputGroup,
  NonIdealState,
  Toaster,
} from "@blueprintjs/core";
import { Editor } from "@tinymce/tinymce-react";
import { ActivityToolbar } from "./ActivityToolbar";

import { JournalPost } from "../lib/definitions";
import { apiPutActivityData, apiSaveActivityData } from "../lib/net";
import { useActivityData } from "../hooks/useActivity";

import styles from "./OnlineJournal.module.css";

interface PostEditorProps {
  isDirty: boolean;
  isSaving: boolean;
  savedPost: JournalPost;
  onSavePost: (updatedPost: JournalPost) => void;
  onCloseEditor: () => void;
  onPostChanged: () => void;
}

interface PostProps {
  post: JournalPost;
  onOpenActivity?: (id: string) => void;
}

export function OnlineJournal() {
  const {
    isLoading: loadingActivities,
    data,
    refetch,
  } = useActivityData("online-journal");

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [selectedJournal, setSelectedJournal] = useState<JournalPost | null>(
    null
  );
  const [showPostEditor, setShowPostEditor] = useState<boolean>(false);

  const saveActivity = async (updatedPost: JournalPost) => {
    if (dirty) {
      const toaster = Toaster.create();

      if (updatedPost.title === "" || updatedPost.content === "") {
        alert("Your journal entry must have a title and content.");
        return;
      }

      try {
        const data = JSON.stringify(updatedPost);

        setIsSaving(true);

        if (updatedPost.id) {
          await apiPutActivityData("online-journal", {
            id: updatedPost.id,
            data,
          });
        } else {
          await apiSaveActivityData("online-journal", {
            data,
          });
        }

        setIsSaving(false);
        setDirty(false);
        setShowPostEditor(false);
        setSelectedJournal(null);
        refetch();

        toaster.show({
          message: "Activity Saved.",
          intent: "success",
        });
      } catch (err: any) {
        setIsSaving(false);
        toaster.show({
          message: "Error saving activity: " + (err as Error).message,
        });
      }
    }
  };

  const newActivity = () => {
    setSelectedJournal({
      title: "",
      content: "",
      comments: [],
    });

    setDirty(false);
    setShowPostEditor(true);
  };

  const openActivity = (id: string) => {
    if (data) {
      const postData = data.find((d) => d.id === id);

      if (postData) {
        console.log(postData);
        const post = postData.data as JournalPost;
        post.id = id;
        setSelectedJournal(post);
        setShowPostEditor(true);
      }
    }
  };

  const printActivity = () => {
    window.print();
  };

  return (
    <div className={styles.onlineJournal}>
      <ActivityToolbar
        loading={isSaving || loadingActivities}
        savedActivities={[]}
        onNewActivity={newActivity}
        onPrintActivity={printActivity}
      />
      {showPostEditor && selectedJournal ? (
        <PostEditor
          isSaving={isSaving}
          isDirty={dirty}
          savedPost={selectedJournal}
          onCloseEditor={() => {
            setShowPostEditor(false);
          }}
          onPostChanged={() => setDirty(true)}
          onSavePost={(updatedPost) => {
            saveActivity(updatedPost);
          }}
        />
      ) : (
        <div className={styles.postList}>
          {data?.map((data) => {
            const post = data.data as JournalPost;
            post.id = data.id;
            return (
              <Post key={post.id!} post={post} onOpenActivity={openActivity} />
            );
          })}
          {data && data.length === 0 && (
            <NonIdealState
              icon="document"
              title="You have no journal entries."
              description={""}
              action={
                <Button
                  text="Write New Entry"
                  onClick={newActivity}
                  intent="primary"
                />
              }
            />
          )}
        </div>
      )}
    </div>
  );
}

export function Post({ post, onOpenActivity }: PostProps) {
  return (
    <article className={styles.post} key={post.id}>
      <header>
        <h1>{post.title}</h1>
        <div className="buttons">
          {onOpenActivity && (
            <Button
              icon="edit"
              text="Edit this Post"
              className="noPrint"
              onClick={() => {
                if (onOpenActivity) {
                  onOpenActivity(post.id!);
                }
              }}
            />
          )}
        </div>
      </header>

      <div
        className={styles.postContent}
        dangerouslySetInnerHTML={{ __html: post.content }}
      ></div>
    </article>
  );
}

function PostEditor({
  isSaving,
  isDirty,
  savedPost,
  onCloseEditor,
  onPostChanged,
  onSavePost,
}: PostEditorProps) {
  const [title, setTitle] = useState<string>(savedPost ? savedPost.title : "");
  const [content, setContent] = useState<string>(
    savedPost ? savedPost.content : ""
  );

  const onSave = () => {
    onSavePost({
      ...savedPost,
      title: title,
      content: content,
    });
  };

  const onCancelPost = () => {
    if (isDirty) {
      if (
        window.confirm("The current post has not been saved.  Discard changes?")
      ) {
        onCloseEditor();
      }
    } else {
      onCloseEditor();
    }
  };

  console.log(savedPost);

  return (
    <div className={styles.postEditor}>
      <h1>Create New Journal Entry</h1>
      <FormGroup label="Post Title">
        <InputGroup
          value={title}
          placeholder="Enter your post title..."
          onChange={(e) => {
            setTitle(e.target.value);
            onPostChanged();
          }}
        />
      </FormGroup>
      <FormGroup label="Post Content">
        <Editor
          onInit={(evt, editor) => {
            console.log("editor ready:", savedPost.content);
            editor.setContent(savedPost.content);
          }}
          onEditorChange={(editorContent) => {
            setContent(editorContent);
            onPostChanged();
          }}
          value={content}
          init={{
            height: 500,
            menubar: false,
            plugins: ["lists"],
            toolbar:
              "undo redo | formatselect | bold italic underline bullist numlist | alignleft aligncenter alignright",
          }}
        />
      </FormGroup>
      <div className={styles.buttons}>
        <Button
          text="Publish Post"
          intent="primary"
          onClick={onSave}
          loading={isSaving}
        />
        <Button text="Cancel" onClick={onCancelPost} disabled={isSaving} />
      </div>
    </div>
  );
}
