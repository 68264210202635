import * as yup from "yup";
import { useState } from "react";

import { useQueryClient } from "react-query";
import { Formik, Form, FormikHelpers } from "formik";

import { SelectFormField } from "./SelectFormField";

import { apiAddStudent } from "../lib/net";
import { Student, StudentGroup } from "../lib/definitions";

import { AppError } from "../lib/errors";
import ErrorAlert from "./ErrorAlert";

import { TextFormField } from "./TextFormField";
import { Button, Classes, Dialog } from "@blueprintjs/core";

interface NewStudentFormProps {
  showForm: boolean;
  onClose: () => void;
  groups: StudentGroup[];
  editStudent: Student | null;
}

export interface NewStudentFormValues {
  username: string;
  password: string;
  group_id: string;
}

const validationSchema = yup.object({
  username: yup.string().required("Student username is required."),
  password: yup.string().required("Student password is required."),
  group_id: yup.string().required("A student group is required."),
});

export function NewStudentForm(props: NewStudentFormProps) {
  const [error, setError] = useState<AppError | null>(null);
  const queryClient = useQueryClient();

  const initialFormValues: NewStudentFormValues = {
    username: "",
    password: "",
    group_id: "",
  };

  if (props.editStudent) {
    initialFormValues.username = props.editStudent.username;
    initialFormValues.password = props.editStudent.password;
    initialFormValues.group_id = props.editStudent.group_id;
  }

  const onFormSubmit = async (
    values: NewStudentFormValues,
    { setSubmitting }: FormikHelpers<NewStudentFormValues>
  ) => {
    setSubmitting(true);
    setError(null);
    try {
      const newStudent: Student = {
        username: values.username,
        password: values.password,
        teacher_id: "",
        id: "",
        group_id: values.group_id,
      };

      await apiAddStudent(newStudent);
      queryClient.invalidateQueries(`students-${newStudent.group_id}`);

      props.onClose();
    } catch (err) {
      if (err instanceof AppError) {
        setError(err);
      }
      setSubmitting(false);
    }
  };

  const title = props.editStudent ? "Edit Student" : "Add New Student";

  return (
    <Dialog isOpen={props.showForm} isCloseButtonShown={false} title={title}>
      {error && <ErrorAlert err={error} />}

      <Formik
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <div className={Classes.DIALOG_BODY}>
              <div className="field">
                <SelectFormField
                  required
                  name="group_id"
                  label="Student Group"
                  placeholder="Select a Group"
                  options={props.groups.map((group) => {
                    return {
                      label: group.name,
                      value: group.id,
                    };
                  })}
                />
              </div>
              <div className="field">
                <TextFormField
                  required={true}
                  name="username"
                  label="Username"
                  disabled={isSubmitting}
                  autoComplete="off"
                />
              </div>
              <div className="field">
                <TextFormField
                  required={true}
                  name="password"
                  label="Password"
                  disabled={isSubmitting}
                  autoComplete="off"
                  hint="Try to use unique, non-easily guessable passwords."
                />
              </div>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  disabled={isSubmitting}
                  onClick={(e: any) => {
                    e.preventDefault();
                    props.onClose();
                  }}
                  text="Cancel"
                />

                <Button
                  loading={isSubmitting}
                  intent="primary"
                  text="Save Student"
                  onClick={() => {
                    submitForm();
                  }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
