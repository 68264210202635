import { useState } from "react";
import classNames from "classnames/bind";
import {
  Button,
  Toaster,
  InputGroup,
  FormGroup,
  TextArea,
} from "@blueprintjs/core";
import { apiPutActivityData, apiSaveActivityData } from "../lib/net";
import { ActivityToolbar } from "./ActivityToolbar";

import { useActivityData } from "../hooks/useActivity";

import styles from "./TimelineBuilder.module.css";

const cx = classNames.bind(styles);

export interface Timeline {
  id?: string;
  name: string;
  events: TimelineEvent[];
}

interface TimelineEvent {
  title: string;
  description: string;
  [key: string]: any;
}

interface TimelineFormProps {
  events: TimelineEvent[];
  onAddNewEvent: () => void;
  onUpdateEvent: (index: number, event: TimelineEvent) => void;
}

interface TimelineProps {
  timeline: Timeline;
}

export function TimelineBuilder() {
  const { isLoading: loadingActivities, data } =
    useActivityData("timeline-builder");

  const savedTimelines = data
    ? data.map((d) => {
        return {
          id: d.id || "",
          name: d.data.name,
        };
      })
    : [];

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [timeline, setTimeline] = useState<Timeline>({
    name: "",
    events: [
      { title: "Enter a title / date", description: "Explain the event here" },
    ],
  });

  const addNewEvent = () => {
    const updatedTimeline = { ...timeline };
    updatedTimeline.events.push({
      title: "",
      description: "",
    });
    setTimeline(updatedTimeline);
    setDirty(true);
  };

  const updateEvent = (index: number, event: TimelineEvent) => {
    const updatedTimeline = { ...timeline };
    updatedTimeline.events[index] = event;
    setTimeline(updatedTimeline);
    setDirty(true);
  };

  const updateTimelineName = (name: string) => {
    const updatedTimeline = { ...timeline };
    updatedTimeline.name = name;
    setTimeline(updatedTimeline);
    setDirty(true);
  };

  const saveActivity = async () => {
    if (dirty) {
      const toaster = Toaster.create();
      try {
        const data: any = JSON.stringify(timeline);
        setIsSaving(true);
        if (timeline.id) {
          await apiPutActivityData("timeline-builder", {
            id: timeline.id,
            data,
          });
        } else {
          const id = await apiSaveActivityData("timeline-builder", {
            data,
          });

          const updatedTimeline = { ...timeline };
          updatedTimeline.id = id;
          setTimeline(updatedTimeline);
        }

        setIsSaving(false);
        setDirty(false);

        toaster.show({
          message: "Activity Saved.",
          intent: "success",
        });
      } catch (err: any) {
        setIsSaving(false);
        toaster.show({
          message: "Error saving activity: " + (err as Error).message,
        });
      }
    }
  };

  const newActivity = () => {
    if (dirty) {
      if (
        !window.confirm(
          "The current timeline is unsaved.  Start a new timeline?"
        )
      ) {
        return;
      }
    }

    setTimeline({
      name: "",
      events: [
        {
          title: "Enter a title / date",
          description: "Explain the event here",
        },
      ],
    });
    setDirty(false);
  };

  const openActivity = (id: string) => {
    if (data) {
      const openTimeline = data.find((d) => d.id === id);
      if (openTimeline) {
        console.log(openTimeline.data);
        setTimeline(openTimeline.data);
      }
    }
  };

  const printActivity = () => {
    window.print();
  };

  return (
    <div className={styles.timelineBuilder}>
      <ActivityToolbar
        loading={loadingActivities || isSaving}
        savedActivities={savedTimelines}
        onSaveActivity={saveActivity}
        onNewActivity={newActivity}
        onOpenActivity={openActivity}
        onPrintActivity={printActivity}
      />
      <div
        className={cx("timelineName", {
          noPrint: true,
        })}
      >
        <input
          type="text"
          placeholder="Give your timeline a name..."
          value={timeline.name}
          onChange={(e) => updateTimelineName(e.target.value)}
        />
      </div>
      <div className={styles.columns}>
        <TimelineForm
          events={timeline.events}
          onAddNewEvent={addNewEvent}
          onUpdateEvent={updateEvent}
        />
        <TimelineDisplay timeline={timeline} />
      </div>
    </div>
  );
}

export function TimelineDisplay({ timeline }: TimelineProps) {
  return (
    <div className={styles.activeTimeline}>
      <h1>{timeline.name === "" ? "New Unnamed Timeline" : timeline.name}</h1>
      <div className={styles.timeline}>
        <div className={styles.bar}></div>
        <div className={styles.timelineEvents}>
          <ul>
            {timeline.events.map((event, index) => {
              return (
                <li key={index}>
                  <div className="inner">
                    <div className="text">{event.description}</div>
                    <div className="year">{event.title}</div>
                    <span className="bar"></span>
                    <span className="dot"></span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

function TimelineForm({
  events,
  onAddNewEvent,
  onUpdateEvent,
}: TimelineFormProps) {
  return (
    <div
      className={cx("timelineForm", {
        noPrint: true,
      })}
    >
      <h2>Add Timeline Events</h2>

      <Button intent="primary" onClick={onAddNewEvent}>
        Add Event
      </Button>

      <div className={styles.events}>
        {events.map((event, index) => {
          return (
            <div className={styles.event} key={index}>
              <FormGroup label="Event Title / Date">
                <InputGroup
                  value={event.title}
                  name="title"
                  onChange={(e: any) => {
                    const updatedEvent = events[index];
                    updatedEvent[e.target.name] = e.target.value;
                    onUpdateEvent(index, updatedEvent);
                  }}
                />
              </FormGroup>
              <FormGroup label="Event Description">
                <TextArea
                  fill
                  value={event.description}
                  name="description"
                  onChange={(e: any) => {
                    const updatedEvent = events[index];
                    updatedEvent[e.target.name] = e.target.value;
                    onUpdateEvent(index, updatedEvent);
                  }}
                />
              </FormGroup>
            </div>
          );
        })}
      </div>
    </div>
  );
}
