import { Content } from "../lib/definitions";
import { useHistory } from "react-router-dom";

import parseJSON from "date-fns/parseJSON";
import format from "date-fns/format";

import styles from "./AgHistoryNews.module.css";
import { Link } from "react-router-dom";
import { Button, NonIdealState, Spinner } from "@blueprintjs/core";
import { useContent } from "../hooks/useContent";

export function AgHistoryNews() {
  const history = useHistory();
  const { isLoading, data: articles } = useContent("student_news", 1);

  return (
    <section className={styles.agHistoryNews}>
      <h1>Ag &amp; History News</h1>
      {isLoading && <Spinner />}

      {articles?.length === 0 && (
        <NonIdealState
          title="No published ag & history news."
          icon="document"
          description="Check back later for more news."
          className={styles.empty}
        />
      )}

      {articles?.map((a) => {
        return <AgHistoryArticleTeaser key={a.id} content={a} />;
      })}

      <div className="buttons">
        <Button
          onClick={() => history.push("/students/ag-history-news")}
          intent="primary"
          text="More Ag & History News"
        />
      </div>
    </section>
  );
}

export function AgHistoryArticleTeaser({ content }: { content: Content }) {
  const createdAtDate = parseJSON(content.created_at);

  return (
    <article className={styles.articleTeaser}>
      <header>
        <h1>
          <Link to={`/students/ag-history-news/${content.slug}`}>
            {content.title}
          </Link>
        </h1>
        <div className={styles.postDate}>{format(createdAtDate, "PP")}</div>
      </header>
      <div className={styles.teaser}>{content.teaser}</div>
      <Link
        className={styles.readMore}
        to={`/students/ag-history-news/${content.slug}`}
      >
        Read More
      </Link>
    </article>
  );
}
