import * as yup from "yup";
import { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import { Button, Callout } from "@blueprintjs/core";

import { AppError, AppErrorCodes } from "../lib/errors";

import { TextFormField } from "./TextFormField";
import { useAuth } from "../hooks/useAuth";

import styles from "./LoginForm.module.css";

interface RequestPasswordFormInterface {
  email: string;
}

const requestPasswordValidationSchema = yup.object({
  email: yup.string().required("Your account email address is required."),
});

export function RequestPasswordForm() {
  const [errorCode, setErrorCode] = useState<AppErrorCodes | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const { requestPassword } = useAuth();
  const history = useHistory();

  const initialFormValues: RequestPasswordFormInterface = {
    email: "",
  };

  const onFormSubmit = async (
    values: RequestPasswordFormInterface,
    { setSubmitting }: FormikHelpers<RequestPasswordFormInterface>
  ) => {
    setErrorCode(null);
    setIsSuccess(false);
    setSubmitting(true);

    try {
      await requestPassword(values.email);
      setIsSuccess(true);
    } catch (err) {
      if (err instanceof AppError) {
        setErrorCode(err.getAppErrorCode());
      }
      setSubmitting(false);
    }
  };

  return (
    <section className={styles.loginForm}>
      <h1>Request New Password</h1>
      {errorCode && errorCode !== AppErrorCodes.UserUnauthorized && (
        <Callout intent="danger" title="An error occurred.">
          Error Code {errorCode}
        </Callout>
      )}

      {isSuccess && (
        <Callout intent="success" title="Password Request Submitted">
          If an account exists for this email address, you will soon receive an
          email containing your new temporary password. Be sure to check your
          junk or spam inboxes.
        </Callout>
      )}

      <Formik
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validationSchema={requestPasswordValidationSchema}
      >
        {({ isSubmitting, submitForm }) => (
          <Form className={styles.form}>
            <div className={styles.field}>
              <TextFormField
                required={true}
                type="email"
                name="email"
                label="Email Address"
                disabled={isSubmitting}
                hint="Enter your account email address."
              />
            </div>
            <div className={styles.buttons}>
              <Button
                intent="primary"
                loading={isSubmitting}
                large
                onClick={() => {
                  submitForm();
                }}
                text="Request New Password"
              />
              <Button
                large
                onClick={(e: any) => {
                  e.preventDefault();
                  history.push("/login");
                }}
                disabled={isSubmitting}
                text="Cancel"
              />
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
}
