import * as yup from "yup";

import { useState } from "react";
import { useQueryClient } from "react-query";
import { Formik, Form, FormikHelpers } from "formik";

import ErrorAlert from "./ErrorAlert";

import { AppError } from "../lib/errors";
import { StudentGroup } from "../lib/definitions";
import { apiAddStudentGroup } from "../lib/net";

import { Button, Classes, Dialog } from "@blueprintjs/core";
import { TextFormField } from "./TextFormField";

interface NewStudentGroupFormProps {
  showForm: boolean;
  onClose: () => void;
  editGroup: StudentGroup | null;
}

export interface NewStudentGroupFormValues {
  name: string;
}

const validationSchema = yup.object({
  name: yup.string().required("A group name is required."),
});

export function NewStudentGroupForm(props: NewStudentGroupFormProps) {
  const [error, setError] = useState<AppError | null>(null);

  const queryClient = useQueryClient();

  const initialFormValues: NewStudentGroupFormValues = {
    name: "",
  };

  const onFormSubmit = async (
    values: NewStudentGroupFormValues,
    { setSubmitting }: FormikHelpers<NewStudentGroupFormValues>
  ) => {
    setSubmitting(true);
    setError(null);
    try {
      await apiAddStudentGroup({
        name: values.name,
        id: "",
        teacher_id: "",
      });
      queryClient.invalidateQueries("studentgroups");

      props.onClose();
    } catch (err) {
      if (err instanceof AppError) {
        setError(err);
      }
      setSubmitting(false);
    }
  };

  const title = props.editGroup ? "Edit Group" : "Add New Student Group";

  return (
    <Dialog isOpen={props.showForm} title={title} isCloseButtonShown={false}>
      {error && <ErrorAlert err={error} />}

      <Formik
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <div className={Classes.DIALOG_BODY}>
              <div className="field">
                <TextFormField
                  required={true}
                  name="name"
                  label="Group Name"
                  disabled={isSubmitting}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  disabled={isSubmitting}
                  onClick={(e: any) => {
                    e.preventDefault();
                    props.onClose();
                  }}
                  text="Cancel"
                />

                <Button
                  loading={isSubmitting}
                  intent="primary"
                  text="Save Group"
                  onClick={() => {
                    submitForm();
                  }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
