import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useStudentGroups, useStudents } from "../../hooks/useStudents";
import { ActivityData, Student, StudentGroup } from "../../lib/definitions";

import styles from "../../components/StudentGroup.module.css";
import { useAllActivityData } from "../../hooks/useActivity";
import { NonIdealState, Spinner, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { useState } from "react";
import { ActivityViewer } from "../../components/ActivityViewer";

interface StudentGroupActivitiesProp {
  group: StudentGroup;
  activities: ActivityData[];
  onSelectActivity: (a: ActivityData, student: Student) => void;
}

function getActivityName(slug: string) {
  if (slug === "map-builder") {
    return "Map Builder";
  }

  if (slug === "timeline-builder") {
    return "Timeline Builder";
  }

  if (slug === "online-journal") {
    return "Online Journal";
  }

  return "Unknown Activity";
}

export function ActivitiesPage() {
  const [viewedActivity, setViewedActivity] = useState<ActivityData | null>();
  const [viewedStudent, setViewedStudent] = useState<Student | null>();

  const params = useParams() as any;

  const activityName = getActivityName(params.activity);

  const { isLoading, data: groups } = useStudentGroups();
  const { isLoading: loadingActivities, data: activities } = useAllActivityData(
    params.activity
  );

  const onActivitySelected = (a: ActivityData, student: Student) => {
    setViewedActivity(a);
    setViewedStudent(student);
  };

  return (
    <div className="landingPage">
      <Helmet>
        <title>{`${activityName} Student Submissions  - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <header>
        <h1>{activityName}</h1>
      </header>
      <section className="section">
        <h1>Activities by Student Group</h1>
        <header className={styles.buttonHeader}></header>
        {(isLoading || loadingActivities) && <Spinner />}
        {groups?.map((group) => {
          return (
            <StudentGroupActivities
              activities={activities ?? []}
              key={group.id}
              group={group}
              onSelectActivity={onActivitySelected}
            />
          );
        })}
        {!isLoading && groups?.length === 0 && (
          <NonIdealState
            title="You have no student groups yet"
            icon="new-person"
          />
        )}
      </section>
      {viewedActivity && (
        <ActivityViewer
          onClose={() => {
            setViewedActivity(null);
            setViewedStudent(null);
          }}
          showDialog={true}
          student={viewedStudent!}
          activity={viewedActivity}
        />
      )}
    </div>
  );
}

function StudentGroupActivities(props: StudentGroupActivitiesProp) {
  const { isLoading, data } = useStudents(props.group.id);

  const studentActivities = new Map<string, ActivityData[]>();
  const studentMap = new Map<string, Student>();

  const onRowClick = (event: TreeNodeInfo<{}>) => {
    if (event.nodeData) {
      const nodeData = event.nodeData as any;
      if (nodeData.type === "activity") {
        const studentId = nodeData.activity.owner_id as string;
        const student = studentMap.get(studentId.replace("student|", ""));
        if (student) {
          props.onSelectActivity(nodeData.activity, student);
        }
      }
    }
  };

  data?.forEach((student) => {
    const activities = props.activities.filter((a) => {
      return a.owner_id === `student|${student.id}`;
    });

    studentActivities.set(student.id, activities);
    studentMap.set(student.id, student);
  });

  const treeState: TreeNodeInfo[] = [];

  studentMap.forEach((student, studentId) => {
    const activities = studentActivities.get(studentId);
    treeState.push({
      id: studentId,
      label: student.username,
      hasCaret: true,
      isExpanded: true,
      icon: "user",
      nodeData: {
        type: "student",
      },
      secondaryLabel: `${activities ? activities.length : 0} entries`,
      childNodes: activities?.map((a) => {
        const activityData = a.data;
        return {
          id: a.id!,
          label: activityData.name ? activityData.name : activityData.title,
          hasCaret: false,
          icon: "document",
          nodeData: {
            type: "activity",
            activity: a,
          },
        };
      }),
    });
  });

  return (
    <div className={styles.studentGroup}>
      <header>
        <h2>{props.group.name !== "" ? props.group.name : "<no name>"}</h2>
      </header>
      {isLoading && <Spinner />}
      <Tree contents={treeState} onNodeDoubleClick={onRowClick} />
      {!isLoading && treeState.length === 0 && (
        <NonIdealState
          icon="user"
          title={`No students have been added to ${props.group.name}.`}
        />
      )}
    </div>
  );
}
