import { useParams, useHistory } from "react-router";
import { Breadcrumbs, BreadcrumbProps } from "@blueprintjs/core";
import classNames from "classnames/bind";

import { TimelineBuilder } from "../../activities/TimelineBuilder";

import styles from "./ActivitiesPage.module.css";
import { MapBuilder } from "../../activities/MapBuilder";
import { OnlineJournal } from "../../activities/OnlineJournal";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const cx = classNames.bind(styles);

function getActivity(slug: string) {
  if (slug === "timeline-builder") {
    return {
      title: "Timeline Builder",
      component: <TimelineBuilder />,
    };
  }

  if (slug === "map-builder") {
    return {
      title: "Map Builder",
      component: <MapBuilder />,
    };
  }

  if (slug === "online-journal") {
    return {
      title: "Online Journal",
      component: <OnlineJournal />,
    };
  }

  return {
    title: "Unknown Activity",
    component: <div>Unknown activity</div>,
  };
}

export function ActivitiesPage() {
  const params = useParams() as any;
  const history = useHistory();

  const activity = getActivity(params.activity);

  const townPageBreadcrumbs: BreadcrumbProps[] = [
    { onClick: () => history.push("/students"), text: "Students" },
    { text: "Activities" },
    { text: activity.title ?? "...loading..." },
  ];

  useEffect(() => {
    window.gtag("event", "activity", {
      activity: activity.title,
    });
  }, [activity]);

  return (
    <div className="activitiesPage">
      <Helmet>
        <title>{`${activity.title} - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <header className="noPrint">
        <h1>{activity.title}</h1>
      </header>
      <Breadcrumbs
        className={cx("breadcrumbs", {
          noPrint: true,
        })}
        items={townPageBreadcrumbs}
      />

      <div className="component">{activity.component}</div>
    </div>
  );
}
