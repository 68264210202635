import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import { Switch, Route, Router, Redirect, RouteProps } from "react-router-dom";
import AuthContextProvider, { useAuth } from "./hooks/useAuth";

import { LoginPage } from "./pages/Login";
import { TeacherDashboardPage } from "./pages/TeacherDashboard";
import { StudentDashboardPage } from "./pages/StudentDashboard";

import { createBrowserHistory } from "history";
import { RequestPassword } from "./pages/RequestPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { Spinner } from "@blueprintjs/core";

const history = createBrowserHistory();

const queryClient = new QueryClient();

const TrackedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  useEffect(() => {
    window.gtag("config", process.env.REACT_GA_ID!, {
      page_title: document.title,
      page_path: window.location.pathname + window.location.search,
    });
  }, []);

  return <Route {...rest} />;
};

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isLoggedIn } = useAuth();
  if (isLoggedIn) {
    return <TrackedRoute {...rest} />;
  } else {
    return <Redirect to={{ pathname: "/login" }} />;
  }
};

const TeacherRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { user } = useAuth();
  if (user.account_type === "teacher") {
    return <TrackedRoute {...rest} />;
  }
  return <Redirect to={{ pathname: "/students" }} />;
};

function IndexPath() {
  const { user } = useAuth();
  if (user.reset_password) {
    return <Redirect to={{ pathname: "/reset-password" }} />;
  }

  if (user.account_type === "teacher") {
    return <Redirect to={{ pathname: "/teachers" }} />;
  } else {
    return <Redirect to={{ pathname: "/students" }} />;
  }
}

function AppRouter() {
  const { isReady } = useAuth();

  if (!isReady) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner intent="primary" size={150} />
      </div>
    );
  }

  return (
    <Router history={history}>
      <Switch>
        <TrackedRoute
          exact
          path="/request-password"
          component={RequestPassword}
        />
        <TrackedRoute exact path="/login" component={LoginPage} />
        <PrivateRoute exact path="/" component={IndexPath} />
        <TrackedRoute exact path="/reset-password" component={ResetPassword} />
        <TeacherRoute path="/teachers" component={TeacherDashboardPage} />
        <PrivateRoute path="/students" component={StudentDashboardPage} />
      </Switch>
    </Router>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <AppRouter />
      </AuthContextProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
