import { Button, HTMLTable, NonIdealState, Spinner } from "@blueprintjs/core";

import { StudentGroup, Student } from "../lib/definitions";

import { useStudents } from "../hooks/useStudents";

import styles from "./StudentGroup.module.css";

export interface StudentGroupProps {
  group: StudentGroup;
  onSelectEditStudent: (student: Student) => void;
  onSelectDeleteStudent: (student: Student) => void;
  onDeleteGroup: (group: StudentGroup) => void;
  onNewStudent: () => void;
}

export function StudentGroupComponent(props: StudentGroupProps) {
  const { isLoading, isError, data } = useStudents(props.group.id);

  return (
    <div className={styles.studentGroup}>
      <header>
        <h2>{props.group.name !== "" ? props.group.name : "<no name>"}</h2>
        <Button
          text="Delete Group"
          intent="danger"
          icon="trash"
          onClick={() => {
            props.onDeleteGroup(props.group);
          }}
        />
      </header>

      <HTMLTable width={"100%"} striped>
        <thead>
          <tr>
            <th>Username</th>
            <th>Password</th>
            <th>Last Login</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={4}>
                <Spinner />
              </td>
            </tr>
          )}
          {data?.map((student) => (
            <tr key={student.id}>
              <td>{student.username}</td>
              <td>{student.password}</td>
              <td>{student.last_login || "Never"}</td>
              <td>
                <Button
                  icon="edit"
                  onClick={() => {
                    props.onSelectEditStudent(student);
                  }}
                />
                {"  "}
                <Button
                  icon="delete"
                  intent="danger"
                  onClick={() => {
                    props.onSelectDeleteStudent(student);
                  }}
                />
              </td>
            </tr>
          ))}
          {!isLoading && !isError && data?.length === 0 && (
            <tr>
              <td colSpan={4}>
                <NonIdealState
                  icon="user"
                  title={`No students have been added to ${props.group.name}.`}
                  action={
                    <Button
                      text="Add the first student"
                      intent="primary"
                      onClick={() => {
                        props.onNewStudent();
                      }}
                    />
                  }
                />
              </td>
            </tr>
          )}
        </tbody>
      </HTMLTable>
    </div>
  );
}
