import * as yup from "yup";
import { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import { Button, Callout, Tab, Tabs } from "@blueprintjs/core";

import styles from "./LoginForm.module.css";
import { useAuth } from "../hooks/useAuth";
import { AppError, AppErrorCodes } from "../lib/errors";
import { TextFormField } from "./TextFormField";
import { SelectFormField } from "./SelectFormField";
import { useTeacherOptions } from "../hooks/useTeacherOptions";
import { SearchSelectFormField } from "./SearchSelectFormField";

interface LoginFormInterface {
  username: string;
  password: string;
  teacher: string;
}

const teacherValidationSchema = yup.object({
  username: yup.string().required("Your username is required."),
  password: yup.string().required("Your password is required."),
});

const studentValidationSchema = yup.object({
  username: yup.string().required("Your username is required."),
  password: yup.string().required("Your password is required."),
  teacher: yup.string().required("You must select your teacher."),
});

export function LoginForm() {
  const { isLoading, data } = useTeacherOptions();
  const [errorCode, setErrorCode] = useState<AppErrorCodes | null>(null);
  const [tabID, setTabID] = useState<string>("teacher_form_panel");
  const { login } = useAuth();
  const history = useHistory();

  const teacherOptions: {
    label: string;
    value: any;
  }[] = data
    ? data.map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      })
    : [];

  const initialFormValues: LoginFormInterface = {
    username: "",
    password: "",
    teacher: "",
  };

  const onFormSubmit = async (
    values: LoginFormInterface,
    { setSubmitting }: FormikHelpers<LoginFormInterface>
  ) => {
    setErrorCode(null);
    setSubmitting(true);

    try {
      console.log(values);
      await login(values);
      console.log("redirecting...");
      history.push("/");
    } catch (err) {
      console.error(JSON.stringify(err));
      if (err instanceof AppError) {
        setErrorCode(err.getAppErrorCode());
      }
      setSubmitting(false);
    }
  };

  return (
    <section className={styles.loginForm}>
      <h1>Login to Tour SD!</h1>

      {errorCode === AppErrorCodes.UserUnauthorized && (
        <Callout intent="danger" title="Incorrect username and/or password.">
          Double check your username or password. If you don't have an account,
          apply for one here.
        </Callout>
      )}

      {errorCode && errorCode !== AppErrorCodes.UserUnauthorized && (
        <Callout intent="danger" title="An error occurred.">
          Error Code {errorCode}
        </Callout>
      )}

      <Formik
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validationSchema={
          tabID === "teacher_form_panel"
            ? teacherValidationSchema
            : studentValidationSchema
        }
      >
        {({ isSubmitting, submitForm }) => (
          <Form className={styles.form}>
            <Tabs
              animate={true}
              selectedTabId={tabID}
              onChange={(newTabID) => {
                setTabID(newTabID.toString());
              }}
              renderActiveTabPanelOnly={true}
            >
              <Tab
                id="teacher_form_panel"
                title="Teachers"
                panel={
                  <div>
                    <div className={styles.field}>
                      <TextFormField
                        required={true}
                        name="username"
                        label="Username"
                        disabled={isSubmitting}
                        hint="Enter the email for your account."
                      />
                    </div>
                    <div className={styles.field}>
                      <TextFormField
                        required={true}
                        type="password"
                        name="password"
                        label="Password"
                        disabled={isSubmitting}
                        hint="Enter your account password."
                      />
                    </div>
                  </div>
                }
              />
              <Tab
                id="student_form_panel"
                title="Students"
                panel={
                  <div>
                    <div className={styles.field}>
                      <SearchSelectFormField
                        label="Your Teacher"
                        options={teacherOptions}
                        name="teacher"
                        disabled={isLoading}
                      />
                      <TextFormField
                        required={true}
                        name="username"
                        label="Username"
                        disabled={isSubmitting}
                        hint="Enter the username for your account provided by your teacher."
                      />
                    </div>
                    <div className={styles.field}>
                      <TextFormField
                        required={true}
                        type="password"
                        name="password"
                        label="Password"
                        disabled={isSubmitting}
                        hint="Enter the password for your account provided by your teacher."
                      />
                    </div>
                  </div>
                }
              />
            </Tabs>

            <div className={styles.buttons}>
              <Button
                loading={isSubmitting}
                intent="primary"
                text="Login"
                large
                onClick={() => {
                  submitForm();
                }}
              />

              {tabID === "teacher_form_panel" && (
                <Button
                  large
                  onClick={() => {
                    history.push("/request-password");
                  }}
                  disabled={isSubmitting}
                  text="Reset Password"
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
}
