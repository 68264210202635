import { Link, useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./TeacherMenu.module.css";

import logo from "../assets/small_logo.svg";

import dashboardIcon from "../assets/menu_icon_dashboard.svg";
import studentsIcon from "../assets/menu_icon_students.svg";
import resourcesIcon from "../assets/menu_icon_resources.svg";

interface MenuLink {
  path: string;
  icon: any;
  label: string;
}

let cx = classNames.bind(styles);

export function TeacherMenu() {
  const location = useLocation();

  const managementMenu: MenuLink[] = [
    {
      path: "/teachers",
      icon: dashboardIcon,
      label: "Dashboard",
    },
    {
      path: "/teachers/students",
      icon: studentsIcon,
      label: "Students",
    },
    {
      path: "/teachers/resources",
      icon: resourcesIcon,
      label: "Resources",
    },
    {
      path: "/teachers/travel-guide",
      icon: resourcesIcon,
      label: "Travel Guide",
    },
  ];

  const studentActivitiesMenu: MenuLink[] = [
    {
      path: "/teachers/activities/map-builder",
      icon: studentsIcon,
      label: "Map Builder",
    },
    {
      path: "/teachers/activities/timeline-builder",
      icon: studentsIcon,
      label: "Timeline Builder",
    },
    {
      path: "/teachers/activities/online-journal",
      icon: studentsIcon,
      label: "Online Journal",
    },
  ];

  return (
    <div className={styles.menu}>
      <h1 className={styles.logo}>
        <img src={logo} alt="Tour SD!" />
      </h1>

      <section className={styles.navGroup} id="teacher_management_menu">
        <h1>Management</h1>
        <ul>
          {managementMenu.map((menu, index) => {
            const isActive = location.pathname === menu.path;
            return (
              <li key={index}>
                <Link
                  to={menu.path}
                  className={cx({
                    active: isActive,
                  })}
                >
                  <img src={menu.icon} alt={menu.label} />
                  {menu.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </section>

      <section className={styles.navGroup} id="activity_management_menu">
        <h1>Student Activities</h1>
        <ul>
          {studentActivitiesMenu.map((menu, index) => {
            const isActive = location.pathname === menu.path;
            return (
              <li key={index}>
                <Link
                  to={menu.path}
                  className={cx({
                    active: isActive,
                  })}
                >
                  <img src={menu.icon} alt={menu.label} />
                  {menu.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
}
