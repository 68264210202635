import { useQuery } from "react-query";
import { Content } from "../lib/definitions";
import { AppError } from "../lib/errors";
import { apiGetContent, apiGetSingleContent } from "../lib/net";

export function useContent(type: string, count?: number) {
  return useQuery<Content[], AppError>(
    ["content", { type }],
    () => {
      return apiGetContent(type, count);
    },
    {
      enabled: true,
    }
  );
}

export function useSingleContent(type: string, slug: string) {
  return useQuery<Content, AppError>(
    ["content", { type, slug }],
    () => {
      return apiGetSingleContent(type, slug);
    },
    {
      enabled: true,
    }
  );
}
