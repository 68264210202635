import { Helmet } from "react-helmet";
import styles from "./Login.module.css";

import { LoginForm } from "../components/LoginForm";

import logo from "../assets/logo.svg";

export function LoginPage() {
  return (
    <div className="page">
      <Helmet>
        <title>{`Login - ${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <div className={styles.loginPage}>
        <h1 className={styles.logo}>
          <img src={logo} alt="Tour SD!" />
          <small>Portal Version 2.0-beta</small>
        </h1>
        <LoginForm />
      </div>
    </div>
  );
}
